import * as ReportState from "./report";
import * as Counter from "./Counter";
import * as UserState from "./user";
import * as CohortState from "./cohort";
import * as UserAdminState from "./userAdmin";
import * as EmployerState from "./employer";
import * as StudentState from "./student";
import * as InternshipState from "./internship";
import * as AdminSettingsState from "./adminSettings";
import * as ApplicationsState from "./application";

// The top-level state object
export interface ApplicationState {
  counter: Counter.CounterState | undefined;
  user: UserState.UserState | undefined;
  cohort: CohortState.CohortState | undefined;
  employer: EmployerState.EmployerState | undefined;
  student: StudentState.StudentState | undefined;
  internship: InternshipState.InternshipState | undefined;
  userAdmin: UserAdminState.UserAdminState | undefined;
  adminSettings: AdminSettingsState.AdminSettingsState | undefined;
  application: ApplicationsState.ApplicationsState | undefined;
  report: ReportState.ReportState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  counter: Counter.reducer,
  user: UserState.reducer,
  cohort: CohortState.reducer,
  employer: EmployerState.reducer,
  student: StudentState.reducer,
  internship: InternshipState.reducer,
  userAdmin: UserAdminState.reducer,
  adminSettings: AdminSettingsState.reducer,
  application: ApplicationsState.reducer,
  report: ReportState.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
