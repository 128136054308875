import { AccountType } from "./../models/enums/accountType";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { ICreateUser } from "../models/ICreateUser";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import jwt_decode from "jwt-decode";
import { IUser } from "../models/IUser";
import { IEmployer } from "../models/IEmployer";
import { IStudent } from "../models/IStudent";
import { IDistrict } from "../models/IDistrict";
import { ICohort } from "../models/ICohort";
import { create } from "domain";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
  currentUser: IUser;
  selectedUser?: IUser;
  userList: Array<IUser>;
  username: string;
  password: string;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isRequesting: boolean;
  token: string;
  employerList: Array<IEmployer>;
  createUserErrorMessage: string;
  loginErrorMessage: string;
  districtList: Array<IDistrict>;
  cohortList: Array<ICohort>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginAction {
  type: "LOGIN";
}
export interface LogoutAction {
  type: "LOGOUT";
}

export interface GetCohortsAction {
  type: "GET_COHORTS_BEGIN";
}
export interface GetCohortsSuccessAction {
  type: "GET_COHORTS_SUCCESS";
  data: Array<ICohort>;
}
export interface GetCohortsErrorAction {
  type: "GET_COHORTS_ERROR";
}


export interface CreateUserAction {
  type: "CREATE_USER_BEGIN";
  createUser: ICreateUser;
}
export interface CreateUserSuccessAction {
  type: "CREATE_USER_SUCCESS";
}
export interface CreateUserErrorAction {
  type: "CREATE_USER_ERROR";
}
export interface CreateUserErrorEmployerAction {
    type: "CREATE_USER_ERROR_EMPLOYER";
}
export interface LoginUserErrorAction {
  type: "LOGIN_USER_ERROR";
}
export interface LoginNotApprovedUserErrorAction {
  type: "LOGIN_NOT_APPROVED_USER_ERROR";
}
export interface ReceiveTokenAction {
  type: "RECEIVE_TOKEN";
  token: string;
}
export interface RequestTokenAction {
  type: "REQUEST_TOKEN";
  username: string;
  password: string;
}
export interface UpdateUsernameAction {
  type: "UPDATE_USERNAME";
  username: string;
}
export interface UpdatePasswordAction {
  type: "UPDATE_PASSWORD";
  password: string;
}

export interface GetUsersAction {
  type: "GET_USERS_BEGIN";
}
export interface GetUsersSuccessAction {
  type: "GET_USERS_SUCCESS";
  data: Array<IUser>;
}
export interface GetUsersErrorAction {
  type: "GET_USERS_ERROR";
}

export interface UpdateUserAction {
  type: "UPDATE_USER_BEGIN";
  updateUser: IUser;
}
export interface UpdateUserSuccessAction {
  type: "UPDATE_USER_SUCCESS";
}
export interface UpdateUserErrorAction {
  type: "UPDATE_USER_ERROR";
}

export interface GetUserAction {
  type: "GET_USER_BEGIN";
  userId: number;
}
export interface GetUserSuccessAction {
  type: "GET_USER_SUCCESS";
  data: IUser;
}
export interface GetUserErrorAction {
  type: "GET_USER_ERROR";
}

export interface GetCurrentUserAction {
  type: "GET_CURRENT_USER_BEGIN";
  userId: number;
}
export interface GetCurrentUserSuccessAction {
  type: "GET_CURRENT_USER_SUCCESS";
  data: IUser;
}
export interface GetCurrentUserErrorAction {
  type: "GET_CURRENT_USER_ERROR";
}

export interface DeleteUserAction {
  type: "DELETE_USER_BEGIN";
  userId: number;
}
export interface DeleteUserSuccessAction {
  type: "DELETE_USER_SUCCESS";
  data: boolean;
}
export interface DeleteUserErrorAction {
  type: "DELETE_USER_ERROR";
}

export interface RequestPasswordResetAction {
  type: "REQUEST_PASSWORD_RESET_BEGIN";
  userId: number;
}
export interface RequestPasswordResetSuccessAction {
  type: "REQUEST_PASSWORD_RESET_SUCCESS";
  data: IUser;
}
export interface RequestPasswordResetErrorAction {
  type: "REQUEST_PASSWORD_RESET_ERROR";
}

export interface ResetPasswordAction {
  type: "RESET_PASSWORD_BEGIN";
  userId: number;
}
export interface ResetPasswordSuccessAction {
  type: "REQUEST_PASSWORD_RESET_SUCCESS";
  data: IUser;
}
export interface ResetPasswordErrorAction {
  type: "REQUEST_PASSWORD_RESET_ERROR";
}

export interface GetEmployersAction {
  type: "GET_EMPLOYERS_BEGIN";
}
export interface GetEmployersSuccessAction {
  type: "GET_EMPLOYERS_SUCCESS";
  data: Array<IEmployer>;
}
export interface GetEmployersErrorAction {
  type: "GET_EMPLOYERS_ERROR";
}

export interface ClearCurrentUser {
  type: "CLEAR_SELECTED_USER";
}

export interface GetDistrictsAction {
  type: "GET_DISTRICTS_BEGIN";
}
export interface GetDistrictsSuccessAction {
  type: "GET_DISTRICTS_SUCCESS";
  data: Array<IDistrict>;
}
export interface GetDistrictsErrorAction {
  type: "GET_DISTRICTS_ERROR";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | LoginAction
  | LogoutAction
  | GetCohortsAction
  | GetCohortsSuccessAction
  | GetCohortsErrorAction
  | UpdateUsernameAction
  | UpdatePasswordAction
  | ReceiveTokenAction
  | RequestTokenAction
  | GetUsersAction
  | GetUsersSuccessAction
  | GetUsersErrorAction
  | CreateUserAction
  | CreateUserSuccessAction
  | CreateUserErrorAction
  | CreateUserErrorEmployerAction
  | LoginUserErrorAction
  | LoginNotApprovedUserErrorAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserErrorAction
  | GetUserAction
  | GetUserSuccessAction
  | GetUserErrorAction
  | GetCurrentUserAction
  | GetCurrentUserSuccessAction
  | GetCurrentUserErrorAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserErrorAction
  | RequestPasswordResetAction
  | RequestPasswordResetSuccessAction
  | RequestPasswordResetErrorAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordErrorAction
  | GetEmployersAction
  | GetEmployersSuccessAction
  | GetEmployersErrorAction
  | ClearCurrentUser
  | GetDistrictsAction
  | GetDistrictsSuccessAction
  | GetDistrictsErrorAction
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  login: () => ({ type: "LOGIN" } as LoginAction),
  logout: () => ({ type: "LOGOUT" } as LogoutAction),
  getCohorts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/cohorts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createCohort)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICohort>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COHORTS_SUCCESS", data });
        });

      dispatch({ type: "GET_COHORTS_BEGIN" });
    }
  },
  getCohortsSuccess: (data: any) => ({ type: "GET_COHORTS_SUCCESS", data: data } as GetCohortsSuccessAction),
  clearToken: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
  },
  // createUser: (createUser: ICreateUser) => ({ type: 'CREATE_USER_BEGIN', createUser: createUser } as CreateUserAction),
  createUserSuccess: () => ({ type: "CREATE_USER_SUCCESS" } as CreateUserSuccessAction),
  createUserError: () => ({ type: "CREATE_USER_ERROR" } as CreateUserErrorAction),
  createUserErrorEmployer: () => ({ type: "CREATE_USER_ERROR_EMPLOYER" } as CreateUserErrorEmployerAction),
  updateUsername: (username: string) =>
    ({
      type: "UPDATE_USERNAME",
      username: username,
    } as UpdateUsernameAction),
  updatePassword: (password: string) =>
    ({
      type: "UPDATE_PASSWORD",
      password: password,
    } as UpdatePasswordAction),
  receiveToken: (token: string) => ({ type: "RECEIVE_TOKEN", token: token } as ReceiveTokenAction),
  //requestToken: (username: string, password: string) => ({ type: 'REQUEST_TOKEN', username: username, password: password} as RequestTokenAction),
  requestAuthToken:
    (username: string, password: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.user && username && password && !appState.user.isLoggedIn) {
          fetch(`api/auth/login`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: `{"username": "${username}","password": "${password}"}`,
        })
          .then((response) => {
            if (response.status == 401) {
              dispatch({ type: "LOGIN_NOT_APPROVED_USER_ERROR" });
            } else if (response.status == 400) {
              dispatch({ type: "LOGIN_USER_ERROR" });
            }

            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then((data) => {
            if (data.tokenString) {
              localStorage.setItem("token", data.tokenString);
              dispatch({ type: "RECEIVE_TOKEN", token: data.tokenString });
              dispatch({ type: "RECEIVE_TOKEN", token: data.tokenString });
              dispatch(push("/landing"));
            }
          });

        dispatch({
          type: "REQUEST_TOKEN",
          username: username,
          password: password,
        });
      }
    },
  createUser:
    (createUser: ICreateUser): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.user && !appState.user.isLoggedIn) {
        fetch(`api/users`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(createUser),
        })
            .then((response) => {
                const employerEnum: number | undefined = (AccountType as any)[AccountType.Employer];
                const createdUserEnum: number | undefined = (AccountType as any)[createUser.AccountType];

                if (response.status === 500 && employerEnum == createdUserEnum && createUser.EmployerId === 0) {
              dispatch({ type: "CREATE_USER_ERROR_EMPLOYER" });
              }
              else if (response.status === 500)
              {
                  dispatch({ type: "CREATE_USER_ERROR" });
              }
              else {
              var data = response.json() as Promise<IUser>;
              return data;
            }
          })
          .then((data) => {
            if (data) {
              dispatch({ type: "CREATE_USER_SUCCESS" });
              if (data.accountType === AccountType.Student) {
                dispatch(push("/confirmationStudent"));
              } else {
                dispatch(push("/confirmation"));
              }
            }
          });

        dispatch({ type: "CREATE_USER_BEGIN", createUser });
      }
    },
  isUserAdmin: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    return appState && appState.user && appState.user.isAdmin;
  },
  getUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/users`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IUser>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_USERS_SUCCESS", data });
        });

      dispatch({ type: "GET_USERS_BEGIN" });
    }
  },
  getUsersSuccess: (data: any) => ({ type: "GET_USERS_SUCCESS", data: data } as GetUsersSuccessAction),
  updateUserSuccess: () => ({ type: "UPDATE_USER_SUCCESS" } as UpdateUserSuccessAction),
  updateUserError: () => ({ type: "UPDATE_USER_ERROR" } as UpdateUserErrorAction),
  updateUser:
    (updateUser: IUser): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateUser),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_USER_SUCCESS" });
            dispatch(push(`/users`));
          });

        dispatch({ type: "UPDATE_USER_BEGIN", updateUser });
      }
    },
  getUser:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/users/${userId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<IUser>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_USER_SUCCESS", data });
          });

        dispatch({ type: "GET_USER_BEGIN", userId });
      }
    },
  getUserSuccess: (data: any) => ({ type: "GET_USER_SUCCESS", data: data } as GetUserSuccessAction),
  getCurrentUser:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/${userId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
            .then((response) => {
            var data = response.json() as Promise<IUser>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_CURRENT_USER_SUCCESS", data });
          });

        dispatch({ type: "GET_CURRENT_USER_BEGIN", userId });
      }
    },
  getCurrentUserSuccess: (data: any) => ({ type: "GET_CURRENT_USER_SUCCESS", data: data } as GetCurrentUserSuccessAction),
  deleteUser:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/users/${userId}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "DELETE_USER_SUCCESS", data });
          });

        dispatch({ type: "DELETE_USER_BEGIN", userId });
      }
    },
  deleteUserSuccess: (data: any) => ({ type: "DELETE_USER_SUCCESS", data: data } as DeleteUserSuccessAction),
  requestPasswordReset:
    (username: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/${username}/reset`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCESS", data });
            dispatch(push(`/forgotPasswordConfirmation`));
          });
      }
    },
  requestPasswordResetSuccess: (data: any) =>
    ({
      type: "REQUEST_PASSWORD_RESET_SUCCESS",
      data: data,
    } as RequestPasswordResetSuccessAction),
  resetPassword:
    (password: string, token: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/resetpassword?password=${password}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(token),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCESS", data });
            dispatch(push(`/resetPasswordConfirmation`));
          });
      }
    },
  resetPasswordSuccess: (data: any) =>
    ({
      type: "REQUEST_PASSWORD_RESET_SUCCESS",
      data: data,
    } as RequestPasswordResetSuccessAction),
  clearCurrentUser: () => ({ type: "CLEAR_SELECTED_USER" } as ClearCurrentUser),
  getEmployers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/employers`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createEmployer)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IEmployer>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_EMPLOYERS_SUCCESS", data });
        });

      dispatch({ type: "GET_EMPLOYERS_BEGIN" });
    }
  },
  getEmployersSuccess: (data: any) =>
    ({
      type: "GET_EMPLOYERS_SUCCESS",
      data: data,
    } as GetEmployersSuccessAction),
  getDistricts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/students/districts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IDistrict>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_DISTRICTS_SUCCESS", data });
        });

      dispatch({ type: "GET_DISTRICTS_BEGIN" });
    }
  },
  getDistrictsSuccess: (data: any) => ({ type: "GET_DISTRICTS_SUCCESS", data: data } as GetDistrictsSuccessAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
  if (state === undefined) {
    return {
      currentUser: {
        username: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).unique_name : "",
        accountType: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AccountType : "",
        id: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).nameid : "",
        employerId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).EmployerId : "",
        districtId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).DistrictId : "",
        studentId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).StudentId : "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        approved: false,
        created: new Date(),
        updated: undefined,
        passwordHash: "",
        passwordSalt: "",
        district: "",
      },
      selectedUser: undefined,
      username: "",
      password: "",
      isLoggedIn: !!localStorage.getItem("token"),
      isAdmin: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin : false,
      token: localStorage.getItem("token") as string,
      isRequesting: false,
      userList: [],
      employerList: [],
      createUserErrorMessage: "",
      loginErrorMessage: "",
      districtList: [],
      cohortList: [],
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin,
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        username: "",
        password: "",
      };
    case "GET_COHORTS_SUCCESS":
      return {
        ...state,
        cohortList: (action as GetCohortsSuccessAction).data,
      };
    case "CREATE_USER_BEGIN":
      return {
        ...state,
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        createUserErrorMessage: "Username/Email already exists.  Please login to that account, or choose a new username/email address.",
          };
    case "CREATE_USER_ERROR_EMPLOYER":
      return {
        ...state,
        createUserErrorMessage: "Please select an employer, if you don't see your employer, contact agustin@avescholars.org.",
          };
    case "LOGIN_USER_ERROR":
      return {
        ...state,
        loginErrorMessage: "Invalid Username or Password.   Please try again.",
      };
    case "LOGIN_NOT_APPROVED_USER_ERROR":
      return {
        ...state,
        loginErrorMessage: "Your user account has not yet been approved.   If you believe this is in error, please contact Avenue Scholars for assistance.",
      };
    case "UPDATE_USERNAME":
      return {
        ...state,
        username: (action as UpdateUsernameAction).username,
      };
    case "UPDATE_PASSWORD":
      return {
        ...state,
        password: (action as UpdatePasswordAction).password,
      };
    case "REQUEST_TOKEN":
      return {
        ...state,
        isRequesting: true,
      };
    case "RECEIVE_TOKEN":
      return {
        ...state,
        token: (action as ReceiveTokenAction).token,
        isLoggedIn: true,
        isRequesting: false,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        userList: (action as GetUsersSuccessAction).data,
      };
    case "GET_USER_SUCCESS":
      var localState = {
        ...state,
        selectedUser: (action as GetUserSuccessAction).data,
      };
      return localState;
    case "GET_CURRENT_USER_SUCCESS":
      return {
        ...state,
        currentUser: (action as GetCurrentUserSuccessAction).data,
      };
    case "CLEAR_SELECTED_USER":
      return {
        ...state,
        selectedUser: undefined,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        selectedUser: undefined,
      };
    case "GET_EMPLOYERS_SUCCESS":
      return {
        ...state,
        employerList: (action as GetEmployersSuccessAction).data,
      };
    case "GET_DISTRICTS_SUCCESS":
      return {
        ...state,
        districtList: (action as GetDistrictsSuccessAction).data,
      };
    default:
      return state;
  }
};
