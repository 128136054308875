import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

type InternshipListProps = InternshipStore.InternshipState &
  UserStore.UserState &
  typeof InternshipStore.actionCreators &
  typeof UserStore.actionCreators &
  RouteComponentProps<{}>;

function InternshipList(props: InternshipListProps) {
  const [domains, setDomains] = useState([
    { label: "Automotive & Diesel", value: "Automotive & Diesel" },
    { label: "Business", value: "Business" },
    // { label: "Engineering", value: "Engineering" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Information Technology", value: "Information Technology" },
    // { label: "Nonprofit Management", value: "Nonprofit Management" },
    { label: "Trades/Labor/Manufacturing", value: "Trades/Labor/Manufacturing" },
  ]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);
  const [selectedCohorts, setSelectedCohorts] = useState();


  let dt: DataTable | null | undefined;

  useEffect(() => {
    props.getInternships();
    props.getCohorts();
  }, []);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  useEffect(() => {
    console.log(props.internshipList);
  }, [props.internshipList]);

  function approvedTemplate(rowData: any) {
    let value = rowData.isApproved as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function publishedTemplate(rowData: any) {
    let value = rowData.isPublished as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
    }

  function onDomainChange(event: any) {
    if (dt) {
      dt.filter(event.value, "internshipDomain", "equals");
      setSelectedDomain(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }

  function payTemplate(rowData: any, column: any) {
    var minAmount = parseFloat(rowData["payPerHour"]);
    var maxAmount = parseFloat(rowData["payPerHourMax"]);

    return (
      <div>
        {minAmount ? minAmount.toLocaleString("en-US", { style: "currency", currency: "USD" }) : " n/a"}-
        {maxAmount ? maxAmount.toLocaleString("en-US", { style: "currency", currency: "USD" }) : " n/a"}
      </div>
    );
  }

  function handleRowClick(e: any) {
    props.history.push(`/internships/${e.data.id}`);
  }

  function handleAddClick() {
    props.history.push(`/internships/0`);
  }

  function onCohortChange(event: any) {
    if (dt) {
      console.log(event.value);
      dt.filter(event.value, 'selectedCohortIds', 'contains');
      setSelectedCohorts(event.value);
    }
  }

  const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)} placeholder="Global Search" size={50} />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />
    </div>
  );

  const domainFilter = (
    <Dropdown style={{ width: "100%" }} placeholder="Select a Domain" value={selectedDomain} options={domains} onChange={onDomainChange} showClear />
  );

  function locationTemplate(rowData: any) {
    return <React.Fragment>
      {rowData.internshipLocationCity}, {rowData.internshipLocationState}
    </React.Fragment>
  }
  return (
    <React.Fragment>
      <h1>Internships</h1>
      <Button label="Add" icon="pi pi-plus" onClick={($event) => handleAddClick()} />
      <br />
      <br />

      <DataTable
        ref={(el) => (dt = el)}
        value={props.internshipList}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        paginator={true}
        paginatorLeft={paginatorLeft}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={20}
        rowsPerPageOptions={[5, 10, 20, 50]}
      >
        <Column style={{ width: "212px" }} field="employer.employerName" header="Employer" sortable={true} />
        <Column style={{ width: "212px" }} field="internshipLocationAddress1" header="Location" sortable={true} body={locationTemplate} />
        <Column style={{ width: "212px" }} field="internshipTitle" header="Title" sortable={true} />
        <Column style={{ width: "212px" }} field="internshipDomain" header="Domain" sortable={true} filter={true} filterElement={domainFilter} />
        <Column style={{ width: "117px" }} field="payPerHour" header="Pay" body={payTemplate} sortable={true} />
        <Column style={{ width: "117px" }} field="numberOfPositionsAvailable" header="# Posts" sortable={true} />
        <Column style={{ display: "none" }} field="selectedCohortIds" header="Cohorts" sortable={false} />

        {currentType !== "2" ? <Column style={{ width: "117px" }} field="isApproved" header="Approved" body={approvedTemplate} sortable={true} /> : undefined}

        {currentType !== "2" ? (
          <Column style={{ width: "117px" }} field="isPublished" header="Published" body={publishedTemplate} sortable={true} />
              ) : undefined}

        {/*<Column style={{ width: "117px" }} field="summerOnly" header="Summer Only" body={summerOnlyTemplate} sortable={true} />*/}
        <Column style={{ width: "117px" }} field="internshipLength" header="Length of Internship" sortable={true} />

      </DataTable>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.internship, InternshipStore.actionCreators)(InternshipList as any);
