import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as CohortStore from "../../../store/cohort";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

type CohortListProps = CohortStore.CohortState & typeof CohortStore.actionCreators & RouteComponentProps<{}>;

function CohortList(props: CohortListProps) {
  const [cohortList, setCohortList] = useState(props.cohortList);
  const [selectedDomain, setSelectedDomain] = useState("");
  
  const [globalFilter, setGlobalFilter] = useState("");

  let dt: DataTable | null | undefined;

  useEffect(() => {
    props.getCohorts();
  }, []);

  useEffect(() => {
    setCohortList(props.cohortList);
  }, [props.cohortList]);

  function formatStartDate(rowData: any) {
    let value = new Date(rowData.start);
    var start = value.toString() + 'Z';
    var result = new Date(start).toLocaleString();

    return result;
  }

  function formatEndDate(rowData: any) {
    let value = new Date(rowData.end);
    var end = value.toString() + 'Z';
    var result = new Date(end).toLocaleString();

    return result;
  }

  function nameTemplate(rowData: any) {
    return `${rowData.lastName}, ${rowData.firstName}`;
  }

    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
          placeholder="Global Search"
          size={50}
        />
        {/* <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button> */}
      </div>
    );

    const paginatorLeft = <Button icon="pi pi-refresh" />;

    const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;

    return (
      <React.Fragment>
        <h1>Cohorts</h1>
          <div>
            <Button label="Add" icon="pi pi-plus" onClick={($event) => handleAddClick()} />
            <br />
            <br />
          </div>

        <DataTable
          ref={(el) => (dt = el)}
          value={props.cohortList}
          onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          header={header}
          globalFilter={globalFilter}
          paginator={true}
          paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={20}
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          <Column field="name" header="Cohort" sortable={true} />
          <Column field="start" header="Start" sortable={true} body={formatStartDate} />
          <Column field="end" header="End" sortable={true} body={formatEndDate} />
          {/* <Column field="lastName" header="Name" sortable={true} body={nameTemplate} />
          <Column field="firstName" header="Name" sortable={true} style={{ display: "none" }} />
          <Column field="email" header="email" sortable={true} style={{ width: "220px" }} />
          
          <Column field="created" header="Created" sortable={true} body={formatDate} style={{ width: "100px" }} />
          <Column field="employer.employerName" header="Employer Name" sortable={true} style={{ display: "none" }} />
          <Column field="student.highSchool" header="High School" sortable={true} style={{ display: "none" }} /> */}
        </DataTable>
      </React.Fragment>
    );


  function handleRowClick(e: any) {
    props.history.push(`/cohorts/${e.data.id}`);
  }

  function handleAddClick() {
    props.history.push(`/cohorts/0`);
  }
}

export default connect((state: ApplicationState) => state.cohort, CohortStore.actionCreators)(CohortList as any);

