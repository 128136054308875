import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as ApplicationsStore from "../../../store/application";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IInternship } from "../../../models/IInternship";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { IApplication } from "../../../models/IApplication";
import { FileUpload } from "primereact/fileupload";
import { ToastContainer, toast } from "react-toastify";

type AttachResumeProps = ApplicationsStore.ApplicationsState & typeof ApplicationsStore.actionCreators & RouteComponentProps<{}>;

class AttachResume extends React.PureComponent<AttachResumeProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: 0,
      useProfileResume: false,
      hasAddedResume: false,
      hasLoadedData: false,
    };

    this.application = {};

    this.myRef = React.createRef();
  }

  private myRef: any;

  private application: any;

  onUpload: any = () => {
    this.setState({ hasAddedResume: true, useProfileResume: false });
    this.notifyResume();
  };
  private timer: any;

  componentDidMount() {
    this.handleScroll();

    const id = (this.props.match.params as any).applicationId;
    this.props.getApplication(id);

    this.timer = setInterval(() => this.setData(), 1000);
    this.setState({
      id: id,
    });
  }

  handleScroll = () => {
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  private setData() {
    if (!this.state.hasLoadedData) {
      this.application = this.props.currentApplication;
      if (this.props.currentApplication?.student.hasResume) {
        this.setState({ useProfileResume: true });
      }
      this.setState({ hasLoadedData: true });
    }
  }

  notify: any = () => toast.success("Please Review Application.");
  notifyResume: any = () => toast.success("Resume Saved!");

  public render() {
    return (
      <React.Fragment>
        <Card title="Add your resume or use your profile resume?" subTitle="" className="preview-card">
          <div ref={this.myRef}>
            <label>Upload a Resume:</label>
            <br />
            <FileUpload auto={true} name="logo" url={`./api/applications/resume/upload/${this.state.id}`} onUpload={this.onUpload}></FileUpload>
            <br />
            <br />
            <h3>OR</h3>
            <br />
            <br />
            {this.props.currentApplication?.student.hasResume ? (
              <div>
                <label>Use Profile Resume:</label>
                <br />
                <InputSwitch checked={this.state.useProfileResume} onChange={(e) => this.setState({ useProfileResume: e.value })} />
                <br />

                <a target="_blank" href={`./api/students/${this.application.studentId}/resume`}>
                  <img width="40px" src="/images/document.png"></img> Download/Preview
                </a>
              </div>
            ) : (
              <div>No profile resume exists, please upload a resume in Word or PDF format to complete your application.</div>
            )}
            <br />
            <br />
            <Button
              label="Review Application"
              disabled={!this.state.hasAddedResume && !this.state.useProfileResume}
              onClick={() => {
                if (this.state.hasAddedResume && !this.state.useProfileResume) {
                  this.props.addApplicationResume(this.state.id);
                } else {
                  //this.props.useProfileResume(this.state.id);
                  this.props.history.push(`/applications/${this.state.id}`);
                }
                this.notify();
              }}
            />
          </div>
        </Card>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.application, ApplicationsStore.actionCreators)(AttachResume as any);
