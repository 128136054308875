import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as EmailBlastStore from '../../../store/emailBlast'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IEmailBlast } from '../../../models/IEmailBlast';
import { Card } from 'primereact/card';
import { ToastContainer, toast } from 'react-toastify';
import { FileUpload } from 'primereact/fileupload';
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';

type EmployerEmailBlastProps =
    EmailBlastStore.EmailBlastState &
    typeof EmailBlastStore.actionCreators &
    RouteComponentProps<{}>;

class EmployerEmailBlast extends React.PureComponent<EmployerEmailBlastProps, IEmailBlast> {
    constructor(props: any) {
        super(props);

        this.state = {
            body: '',
            subject: ''
        } as IEmailBlast;
    }

    componentDidMount() {
        
    }

    notify: any = () => toast.success('Message Sent!');

    public render() {

        return (
            <React.Fragment>
                <Card title="Email Employers" subTitle="">

                    <label>Subject:</label><br />
                    <InputText name="subject" value={this.state.subject} onChange={(e) => this.setState({ subject: (e.target as unknown as HTMLTextAreaElement).value })} /><br /><br />

                    <label>Body:</label><br />
                    <Editor  style={{ height: '320px' }} value={this.state.body} onTextChange={(e) => this.setState({ body: e.htmlValue as string })} /><br />

                    <Button label="Save" onClick={() => {
                        this.props.createEmployerEmailBlast(this.state);
                        this.notify();
                        this.props.history.push(`/adminsettings`);
                    }} />
                </Card>
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.adminSettings,
    EmailBlastStore.actionCreators
)(EmployerEmailBlast as any);
