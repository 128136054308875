import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import jwt_decode from "jwt-decode";
import { AccountType } from "../../../models/enums/accountType";
import { useEffect, useState } from "react";

type ApplicantsPerRoleProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;

function ApplicantsPerRole(props: ApplicantsPerRoleProps) {
  let dt: DataTable | null | undefined;
  const [applicantsPerRole, setApplicantsPerRole] = React.useState(props.applicantsPerRole);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);


  useEffect(() => {
    props.getApplicantsPerRole();
    props.getCohorts();
  }, []);

  useEffect(() => {
    setApplicantsPerRole(props.applicantsPerRole);
  }, [props.applicantsPerRole]);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        value={globalFilter ?? ''}
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />
    </div>
  );

  return (
    <React.Fragment>
      {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District && <h1>Applicants Per Role In Your District</h1>}
      {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType != AccountType.District && <h1>Applicants Per Role</h1>}

      <DataTable
        ref={(el) => (dt = el)}
        value={applicantsPerRole}
        //onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
      >
        <Column style={{ width: "120px" }} field="employerName" header="Employer" sortable={true} />
        <Column style={{ width: "120px" }} field="internshipDomain" header="Domain" sortable={true} />
        <Column style={{ width: "120px" }} field="internshipTitle" header="Job Title" sortable={true} />
        <Column style={{ width: "120px" }} field="cohort" header="Cohort" sortable />
        <Column style={{ width: "40px" }} field="payPerHour" header="Min Pay" body={moneyTemplate} sortable={true} />
        <Column style={{ width: "40px" }} field="numberOfPositions" header="# Of Jobs" sortable={true} />
        <Column style={{ width: "40px" }} field="numberOfApplications" header="# Of Apps" sortable={true} />
        <Column style={{ width: "40px" }} field="hiredCount" header="# Hired" sortable={true} />
        <Column field="cohortId" style={{ display: 'none' }} header="Cohort" />
      </DataTable>
    </React.Fragment>
  );


  function moneyTemplate(rowData: any, column: any) {
    var amount = parseFloat(rowData[column.field]);

    return <div>{amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>;
  }

  function handleRowClick(e: any) {
    //props.history.push(`/reports/${e.data.route}`);
  }
}

export default connect((state: ApplicationState) => state.report, ReportStore.actionCreators)(ApplicantsPerRole as any);
