import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { AUTH_ROUTES } from "./auth.routing.constants";
import signup from "../../pages/signup/signup";
import login from "../../pages/login/login";
import confirmation from "../../pages/signup/confirmation";
import signupEmployer from "../../pages/signup/signupEmployer";
import signupStudent from "../../pages/signup/signupStudent";
import signupAdmin from "../../pages/signup/signupAdmin";
import confirmationStudent from "../../pages/signup/confirmationStudent";
import forgotPassword from "../../pages/login/forgotPassword";
import forgotPasswordConfirmation from "../../pages/login/forgotPasswordConfirmation";
import resetPassword from "../../pages/login/resetPassword";
import resetPasswordConfirmation from "../../pages/login/resetPasswordConfirmation";
import signupDistrict from "../../pages/signup/signupDistrict";

export const AuthRouting: FC = () => (
  <Switch>
    <Route path={AUTH_ROUTES.LOGIN} component={login} />
    <Route path={AUTH_ROUTES.SIGNUP} component={signup} />
    <Route path={AUTH_ROUTES.SIGNUP_ADMIN} component={signupAdmin} />
    <Route path={AUTH_ROUTES.SIGNUP_EMPLOYER} component={signupEmployer} />
    <Route path={AUTH_ROUTES.SIGNUP_DISTRICT} component={signupDistrict} />
    <Route path={AUTH_ROUTES.SIGNUP_STUDENT} component={signupStudent} />
    <Route path={AUTH_ROUTES.RESET_PASSWORD} component={resetPassword} />
    <Route path={AUTH_ROUTES.RESET_PASSWORD_CONFIRMATION} component={resetPasswordConfirmation} />
    <Route path={AUTH_ROUTES.FORGOT_PASSWORD} component={forgotPassword} />
    <Route path={AUTH_ROUTES.FORGOT_PASSWORD_CONFIRMATION} component={forgotPasswordConfirmation} />
    <Route path={AUTH_ROUTES.CONFIRMATION} component={confirmation} />
    <Route path={AUTH_ROUTES.CONFIRMATION_STUDENT} component={confirmationStudent} />
  </Switch>
);
