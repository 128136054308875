export const AUTH_ROUTES = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_STUDENT: "/signupStudent",
  SIGNUP_ADMIN: "/signupAdmin",
  SIGNUP_EMPLOYER: "/signupEmployer",
  SIGNUP_DISTRICT: "/signupDistrict",
  CONFIRMATION: "/confirmation",
  CONFIRMATION_STUDENT: "/confirmationStudent",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword/:token",
  RESET_PASSWORD_CONFIRMATION: "/resetPasswordConfirmation",
  FORGOT_PASSWORD_CONFIRMATION: "/forgotPasswordConfirmation",
};
