import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { IInternship } from "../models/IInternship";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import { IEmployer } from "../models/IEmployer";
import { IAdminSettings } from "../models/IAdminSettings";
import { ICohort } from "../models/ICohort";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface InternshipState {
  internshipList: Array<IInternship>;
  currentInternship?: IInternship;
  employerList: Array<IEmployer>;
  currentAdminSettings: IAdminSettings;
  cohortList: Array<ICohort>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateInternshipAction {
  type: "CREATE_INTERNSHIP_BEGIN";
  createInternship: IInternship;
}
export interface CreateInternshipSuccessAction {
  type: "CREATE_INTERNSHIP_SUCCESS";
}
export interface CreateInternshipErrorAction {
  type: "CREATE_INTERNSHIP_ERROR";
}

export interface UpdateInternshipAction {
  type: "UPDATE_INTERNSHIP_BEGIN";
  updateInternship: IInternship;
}
export interface UpdateInternshipSuccessAction {
  type: "UPDATE_INTERNSHIP_SUCCESS";
}
export interface UpdateInternshipErrorAction {
  type: "UPDATE_INTERNSHIP_ERROR";
}

export interface GetInternshipsAction {
  type: "GET_INTERNSHIPS_BEGIN";
}
export interface GetInternshipsSuccessAction {
  type: "GET_INTERNSHIPS_SUCCESS";
  data: Array<IInternship>;
}
export interface GetInternshipsErrorAction {
  type: "GET_INTERNSHIPS_ERROR";
}

export interface GetInternshipAction {
  type: "GET_INTERNSHIP_BEGIN";
  internshipId: number;
}
export interface GetInternshipSuccessAction {
  type: "GET_INTERNSHIP_SUCCESS";
  data: IInternship;
}
export interface GetInternshipErrorAction {
  type: "GET_INTERNSHIP_ERROR";
}

export interface DeleteInternshipAction {
  type: "DELETE_INTERNSHIP_BEGIN";
  internshipId: number;
}
export interface DeleteInternshipSuccessAction {
  type: "DELETE_INTERNSHIP_SUCCESS";
  data: boolean;
}
export interface DeleteInternshipErrorAction {
  type: "DELETE_INTERNSHIP_ERROR";
}

export interface ClearCurrentInternship {
  type: "CLEAR_CURRENT_INTERNSHIP";
}

export interface GetEmployersAction {
  type: "GET_EMPLOYERS_BEGIN";
}
export interface GetEmployersSuccessAction {
  type: "GET_EMPLOYERS_SUCCESS";
  data: Array<IEmployer>;
}
export interface GetEmployersErrorAction {
  type: "GET_EMPLOYERS_ERROR";
}

export interface GetAdminSettingsAction {
  type: "GET_ADMIN_SETTINGS_BEGIN";
  id: number;
}
export interface GetAdminSettingsSuccessAction {
  type: "GET_ADMIN_SETTINGS_SUCCESS";
  data: IAdminSettings;
}
export interface GetAdminSettingsErrorAction {
  type: "GET_ADMIN_SETTINGS_ERROR";
}

export interface GetCohortsAction {
  type: "GET_COHORTS_BEGIN";
}
export interface GetCohortsSuccessAction {
  type: "GET_COHORTS_SUCCESS";
  data: Array<ICohort>;
}
export interface GetCohortsErrorAction {
  type: "GET_COHORTS_ERROR";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | CreateInternshipAction
  | CreateInternshipSuccessAction
  | CreateInternshipErrorAction
  | UpdateInternshipAction
  | UpdateInternshipSuccessAction
  | UpdateInternshipErrorAction
  | GetInternshipsAction
  | GetInternshipsSuccessAction
  | GetInternshipsErrorAction
  | GetInternshipAction
  | GetInternshipSuccessAction
  | GetInternshipErrorAction
  | DeleteInternshipAction
  | DeleteInternshipSuccessAction
  | DeleteInternshipErrorAction
  | GetEmployersAction
  | GetEmployersSuccessAction
  | GetEmployersErrorAction
  | GetAdminSettingsAction
  | GetAdminSettingsSuccessAction
  | GetAdminSettingsErrorAction
  | ClearCurrentInternship
  | GetCohortsAction
  | GetCohortsSuccessAction
  | GetCohortsErrorAction
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  // createInternship: (createInternship: ICreateInternship) => ({ type: 'CREATE_INTERNSHIP_BEGIN', createInternship: createInternship } as CreateInternshipAction),
  createInternshipSuccess: () => ({ type: "CREATE_INTERNSHIP_SUCCESS" } as CreateInternshipSuccessAction),
  createInternshipError: () => ({ type: "CREATE_INTERNSHIP_ERROR" } as CreateInternshipErrorAction),
  createInternship:
    (createInternship: IInternship): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/internships`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(createInternship),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "CREATE_INTERNSHIP_SUCCESS" });
            dispatch(push("/internships"));
          });

        dispatch({ type: "CREATE_INTERNSHIP_BEGIN", createInternship });
      }
    },
  updateInternshipSuccess: () => ({ type: "UPDATE_INTERNSHIP_SUCCESS" } as UpdateInternshipSuccessAction),
  updateInternshipError: () => ({ type: "UPDATE_INTERNSHIP_ERROR" } as UpdateInternshipErrorAction),
  updateInternship:
    (updateInternship: IInternship): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/internships`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateInternship),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_INTERNSHIP_SUCCESS" });
            dispatch(push("/internships"));
          });

        dispatch({ type: "UPDATE_INTERNSHIP_BEGIN", updateInternship });
      }
    },
  getInternships: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/internships`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createInternship)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IInternship>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_INTERNSHIPS_SUCCESS", data });
        });

      dispatch({ type: "GET_INTERNSHIPS_BEGIN" });
    }
  },
  getInternshipsSuccess: (data: any) => ({ type: "GET_INTERNSHIPS_SUCCESS", data: data } as GetInternshipsSuccessAction),
  getInternship:
    (internshipId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/internships/${internshipId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createInternship)
        })
          .then((response) => {
            var data = response.json() as Promise<IInternship>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_INTERNSHIP_SUCCESS", data });
          });

        dispatch({ type: "GET_INTERNSHIP_BEGIN", internshipId });
      }
    },
  getInternshipSuccess: (data: any) => ({ type: "GET_INTERNSHIP_SUCCESS", data: data } as GetInternshipSuccessAction),
  deleteInternship:
    (internshipId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/internships/${internshipId}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createInternship)
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "DELETE_INTERNSHIP_SUCCESS", data });
          });

        dispatch({ type: "DELETE_INTERNSHIP_BEGIN", internshipId });
      }
    },
  deleteInternshipSuccess: (data: any) => ({ type: "GET_INTERNSHIP_SUCCESS", data: data } as GetInternshipSuccessAction),
  clearCurrentInternship: () => ({ type: "CLEAR_CURRENT_INTERNSHIP" } as ClearCurrentInternship),
  getEmployers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/employers`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createEmployer)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IEmployer>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_EMPLOYERS_SUCCESS", data });
        });

      dispatch({ type: "GET_EMPLOYERS_BEGIN" });
    }
  },
  getEmployersSuccess: (data: any) => ({ type: "GET_EMPLOYERS_SUCCESS", data: data } as GetEmployersSuccessAction),
  getAdminSettings:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.adminSettings) {
        fetch(`api/adminsettings/${id}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createAdminSettings)
        })
          .then((response) => {
            var data = response.json() as Promise<IAdminSettings>;
            return data;
          })
          .then((data) => {
            data.applicationOpenDate = new Date(data.applicationOpenDate);
            data.applicationCloseDate = new Date(data.applicationCloseDate);
            dispatch({ type: "GET_ADMIN_SETTINGS_SUCCESS", data });
          });

        dispatch({ type: "GET_ADMIN_SETTINGS_BEGIN", id });
      }
    },
  getAdminSettingsSuccess: (data: any) => ({ type: "GET_ADMIN_SETTINGS_SUCCESS", data: data } as GetAdminSettingsSuccessAction),
  getCohorts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/cohorts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createCohort)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICohort>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COHORTS_SUCCESS", data });
        });

      dispatch({ type: "GET_COHORTS_BEGIN" });
    }
  },
  getCohortsSuccess: (data: any) => ({ type: "GET_COHORTS_SUCCESS", data: data } as GetCohortsSuccessAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<InternshipState> = (state: InternshipState | undefined, incomingAction: Action): InternshipState => {
  if (state === undefined) {
    return {
      internshipList: [],
      currentInternship: undefined,
      employerList: [],
      currentAdminSettings: {} as IAdminSettings,
      cohortList: [],
    };
  }

  const action = incomingAction as KnownAction;  
  switch (action.type) {
    case "CREATE_INTERNSHIP_BEGIN":
      return {
        ...state,
      };
    case "GET_INTERNSHIPS_SUCCESS":
      return {
        ...state,
        internshipList: (action as GetInternshipsSuccessAction).data,
      };
    case "GET_INTERNSHIP_SUCCESS":
      return {
        ...state,
        currentInternship: (action as GetInternshipSuccessAction).data,
      };
    case "DELETE_INTERNSHIP_SUCCESS":
      return {
        ...state,
        currentInternship: undefined,
      };
    case "CLEAR_CURRENT_INTERNSHIP":
      return {
        ...state,
        currentInternship: undefined,
      };
    case "GET_EMPLOYERS_SUCCESS":
      return {
        ...state,
        employerList: (action as GetEmployersSuccessAction).data,
      };
    case "GET_ADMIN_SETTINGS_SUCCESS":
      return {
        ...state,
        currentAdminSettings: (action as GetAdminSettingsSuccessAction).data,
      };
    case "GET_COHORTS_SUCCESS":
      return {
        ...state,
        cohortList: (action as GetCohortsSuccessAction).data,
      };
    default:
      return state;
  }
};
