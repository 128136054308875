import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (
  <div>
    <h1>Welcome!</h1>
    <p>This is the dashboard page for the Avenue Scholars Interns Web Site.   eventually this will direct a user where they might have work to do.</p>
    
  </div>
);

export default connect()(Home);
