import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as ApplicationStore from '../../../store/application';
import * as UserStore from '../../../store/user';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { AccountType } from '../../../models/enums/accountType';
import jwt_decode from 'jwt-decode';
import Axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from 'react';

type ApplicationListProps =
    ApplicationStore.ApplicationsState & UserStore.UserState &
    typeof ApplicationStore.actionCreators & typeof UserStore.actionCreators &
    RouteComponentProps<{}>;

function ApplicationList(props: ApplicationListProps) {
    const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedCohorts, setSelectedCohorts] = useState();
    const [statuses, setStatus] = useState([
        { label: 'Applied', value: '0' },
        { label: 'Under Review', value: '1' },
        { label: 'Selected For Interview', value: '2' },
        { label: 'Declined', value: '3' },
        { label: 'Offer Out', value: '4' },
        { label: 'Offer Accepted', value: '5' },
        { label: 'Offer Declined', value: '6' },
        { label: 'Pending Submission', value: '10' }
    ]);

    useEffect(() => {
        props.getCohorts();
        props.getApplications();
    }, []);

    useEffect(() => {
        const cohorts = props.cohortList.map((cohort) => {
            return { label: cohort.name, value: cohort.id.toString() };
        });
        setCohortList(cohorts);
    }, [props.cohortList]);

    let dt: DataTable | null | undefined;

    function nameTemplate(rowData: any) {
        return <div>{rowData.student.lastName}, {rowData.student.firstName} <br />  {rowData.student.highSchool}</div>;
    }

    function dateTemplate(rowData: any) {
        return <div>{new Date(rowData.created).toLocaleString().split(',')[0]}</div>;
    }

    function statusTemplate(rowData: any) {
        switch (rowData.applicationStatus) {
            case 0:
                return 'Applied';
                break;
            case 1:
                return 'Under Review';
                break;
            case 2:
                return 'Selected For Interview';
                break;
            case 3:
                return 'Declined';
                break;
            case 4:
                return 'Offer Out';
                break;
            case 5:
                return 'Offer Accepted';
                break;
            case 6:
                return 'Offer Declined';
                break;
            case 10:
                return 'Incomplete Submission';
                break;
        }
    }



    function onStatusChange(event: any) {
        if (dt) {
            dt.filter(event.value, 'applicationStatus', 'equals');
            setSelectedStatus(event.value);
        }

    }

    function onCohortChange(event: any) {
        if (dt) {
            dt.filter(event.value, 'cohortId', 'equals');
            setSelectedCohorts(event.value);
        }
    }

    function onExport() {
        if (dt) dt.exportCSV({ selectionOnly: false });
    }

    function downloadResumes() {
        Axios.get("./api/applications/export", {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then((res) => {
            var newfilename = "";
            var disposition = res.headers["content-disposition"];
            newfilename = disposition.split("filename=")[1].split(";")[0].replaceAll('"', "");
            fileDownload(res.data, newfilename);
        });
    }

    const currentType = ((jwt_decode(localStorage.getItem('token') as string) as any).AccountType);

    const header = (
        <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText
                type="search"
                onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
                placeholder="Global Search"
                size={50}
            />
            <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
            <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="Download All Resumes and Applications Archive" onClick={downloadResumes}></Button>
            <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />

        </div>
    );

    const statusFilter = <Dropdown style={{ width: '100%' }} placeholder="Select a Status" value={selectedStatus} options={statuses} onChange={onStatusChange} showClear />;


    return (
        <React.Fragment>
            <h1>Applications</h1>

            <DataTable
                ref={(el) => dt = el}
                value={props.applicationList}
                onRowClick={$event => handleRowClick($event)}
                selectionMode="single"
                header={header}
                globalFilter={globalFilter}
            >
                {(currentType !== '1') ?
                    <Column field="internship.employer.employerName" header="Employer" sortable={true} />
                    :
                    undefined}
                <Column field="internship.internshipTitle" header="Title" sortable={true} />
                <Column field="internship.payPerHour" header="Pay/Hr" sortable={true} body={payTemplate} />
                <Column field="created" header="Application Date" sortable={true} body={dateTemplate} />
                <Column field="applicationStatus" style={{ width: '220px' }} header="Status" sortable={true} body={statusTemplate} filter={true} filterElement={statusFilter} />
                <Column field="cohortId" style={{ display: 'none' }} header="Cohort" />
                <Column field="student.firstName" header="Name" sortable={true} style={{ display: 'none' }} />
                {/* <Column field="applicationTitle" header="Title" sortable={true} />
                    <Column style={{width:'220px'}} field="applicationDomain" header="Domain" sortable={true} filter={true} filterElement={domainFilter} />
                    <Column field="numberOfPositionsAvailable" header="# Of Positions" sortable={true} />
                    <Column style={{width:'120px'}} field="payPerHour" header="Pay/Hr" body={moneyTemplate} sortable={true} /> */}
                {(currentType !== '2') ?
                    <Column field="student.lastName" header="Applicant" sortable={true} body={nameTemplate} />
                    :
                    undefined}
                {(currentType !== '2') ?
                    <Column field="student.isAvenueScholar" header="Is Ave" body={isAveTemplate} sortable={true} />
                    :
                    undefined}

            </DataTable>
        </React.Fragment>
    );


    function moneyTemplate(rowData: any, column: any) {
        var amount = parseFloat(rowData.internship.payPerHour);

        return <div>
            {amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </div>;
    }

    function isAveTemplate(rowData: any) {
        let value = rowData.student.isAvenueScholar as boolean;

        if (value) {
            return <span>Yes</span>;
        } else {
            return <span>No</span>;
        }
    }

    function payTemplate(rowData: any, column: any) {
        var minAmount = parseFloat(rowData.internship.payPerHour);
        var maxAmount = parseFloat(rowData.internship.payPerHourMax);

        return <div>
            {minAmount ? minAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}-{maxAmount ? maxAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}
        </div>;
    }

    function handleRowClick(e: any) {
        props.history.push(`/applications/${e.data.id}`);
    }

    function handleAddClick() {
        props.history.push(`/applications/0`);
    }
};

export default connect(
    (state: ApplicationState) => state.application,
    ApplicationStore.actionCreators
)(ApplicationList as any);
