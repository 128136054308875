import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ITokenResult } from '../models/ITokenResult';
import { IEmailBlast } from '../models/IEmailBlast';
import { push } from 'react-router-redux';
import { RouterAction } from 'connected-react-router';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EmailBlastState {
    
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateStudentEmailBlastAction { type: 'CREATE_STUDENT_EMAIL_BLAST_BEGIN', createStudentEmailBlast: IEmailBlast }
export interface CreateStudentEmailBlastSuccessAction { type: 'CREATE_STUDENT_EMAIL_BLAST_SUCCESS' }
export interface CreateStudentEmailBlastErrorAction { type: 'CREATE_STUDENT_EMAIL_BLAST_ERROR' }

export interface CreateEmployerEmailBlastAction { type: 'CREATE_EMPLOYER_EMAIL_BLAST_BEGIN', createEmployerEmailBlast: IEmailBlast }
export interface CreateEmployerEmailBlastSuccessAction { type: 'CREATE_EMPLOYER_EMAIL_BLAST_SUCCESS' }
export interface CreateEmployerEmailBlastErrorAction { type: 'CREATE_EMPLOYER_EMAIL_BLAST_ERROR' }



// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = CreateStudentEmailBlastAction | CreateStudentEmailBlastSuccessAction | CreateStudentEmailBlastErrorAction | 
CreateEmployerEmailBlastAction | CreateEmployerEmailBlastSuccessAction | CreateEmployerEmailBlastErrorAction | 
RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    // createStudentEmailBlast: (createStudentEmailBlast: ICreateStudentEmailBlast) => ({ type: 'CREATE_STUDENT_EMAIL_BLAST_BEGIN', createStudentEmailBlast: createStudentEmailBlast } as CreateStudentEmailBlastAction),
    createStudentEmailBlastSuccess: () => ({ type: 'CREATE_STUDENT_EMAIL_BLAST_SUCCESS' } as CreateStudentEmailBlastSuccessAction),
    createStudentEmailBlastError: () => ({ type: 'CREATE_STUDENT_EMAIL_BLAST_ERROR' } as CreateStudentEmailBlastErrorAction),
    createStudentEmailBlast: (createStudentEmailBlast: IEmailBlast): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/adminsettings/studentemailblast`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(createStudentEmailBlast)
            })
                // .then(response => {
                //     var data = response.json() as Promise<ITokenResult>;
                //     return data;
                // })
                .then(() => {
                    dispatch({ type: 'CREATE_STUDENT_EMAIL_BLAST_SUCCESS' });
                    dispatch(push('/adminsettings'));
                });

            dispatch({ type: 'CREATE_STUDENT_EMAIL_BLAST_BEGIN', createStudentEmailBlast });
        }
    },

    createEmployerEmailBlastSuccess: () => ({ type: 'CREATE_EMPLOYER_EMAIL_BLAST_SUCCESS' } as CreateEmployerEmailBlastSuccessAction),
    createEmployerEmailBlastError: () => ({ type: 'CREATE_EMPLOYER_EMAIL_BLAST_ERROR' } as CreateEmployerEmailBlastErrorAction),
    createEmployerEmailBlast: (createEmployerEmailBlast: IEmailBlast): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/adminsettings/employeremailblast`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(createEmployerEmailBlast)
            })
                // .then(response => {
                //     var data = response.json() as Promise<ITokenResult>;
                //     return data;
                // })
                .then(() => {
                    dispatch({ type: 'CREATE_EMPLOYER_EMAIL_BLAST_SUCCESS' });
                    dispatch(push('/adminsettings'));
                });

            dispatch({ type: 'CREATE_EMPLOYER_EMAIL_BLAST_BEGIN', createEmployerEmailBlast });
        }
    },
    
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<EmailBlastState> = (state: EmailBlastState | undefined, incomingAction: Action): EmailBlastState => {
    if (state === undefined) {
        return {
            employerList: [],
            currentStudentEmailBlast: undefined
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATE_STUDENT_EMAIL_BLAST_BEGIN':
            return {
                ...state
            };
        default:
            return state;
    }
};
