import React, {FC} from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {AUTH_ROUTES} from './auth/auth.routing.constants';

export const RedirectToLogin: FC<RouteProps> = ({location}) => (
    <Redirect
        to={{
            pathname: AUTH_ROUTES.LOGIN,
            // state: {
            //     from: location
            // } as LoginNavState
        }}
    />
);

const haveToken = () => {
    var token = localStorage.getItem('token');
    return token;
}

export const ProtectedRouting: FC<RouteProps> = (props) => {
    
    return haveToken() ?
        <Route {...props} /> :
        <Route {...props} component={RedirectToLogin} />;
};
