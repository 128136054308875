import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";

type ReportListProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;

class ReportList extends React.PureComponent<ReportListProps, any> {
  dt: DataTable | null | undefined;

  constructor(props: any) {
    super(props);
    if ((jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District) {
      this.state = {
        reports: [
          { name: "Students in roles", route: "/reports/studentsInRoles" },
          { name: "Student Information", route: "/reports/studentInformation" },
          { name: "Student/School counts", route: "/reports/studentSchoolCounts" },
          { name: "Applicants per role", route: "/reports/applicantsPerRole" },
          { name: "Student Applications", route: "/reports/studentApplications" },
        ],
      };
    } else {
      this.state = {
        reports: [
          { name: "Students in roles", route: "/reports/studentsInRoles" },
          { name: "Student Information", route: "/reports/studentInformation" },
          { name: "Student/School counts", route: "/reports/studentSchoolCounts" },
          { name: "Applicants per role", route: "/reports/applicantsPerRole" },
          { name: "YoY Partner Performance", route: "/reports/yoyPartnerPerformance" },
          { name: "Student Applications", route: "/reports/studentApplications" },
        ],
      };
    }
  }

  componentDidMount() {}

  public render() {
    const header = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilter: (e.target as HTMLTextAreaElement).value })}
          placeholder="Global Search"
          size={50}
        />
      </div>
    );

    return (
      <React.Fragment>
        <h1>Reports</h1>

        <DataTable
          ref={(el) => (this.dt = el)}
          value={this.state.reports}
          onRowClick={($event) => this.handleRowClick($event)}
          selectionMode="single"
          header={header}
          globalFilter={this.state.globalFilter}
        >
          <Column field="name" header="Report" sortable={true} />
        </DataTable>
      </React.Fragment>
    );
  }

  handleRowClick(e: any) {
    this.props.history.push(`${e.data.route}`);
  }
}

export default connect((state: ApplicationState) => state.employer, ReportStore.actionCreators)(ReportList as any);
