import { Action, Reducer } from "redux";
import { AppThunkAction, ApplicationState } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { IApplication } from "../models/IApplication";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import { ApplicationStatus } from "../models/enums/applicationStatus";
import { IInternship } from "../models/IInternship";
import { IStudent } from "../models/IStudent";
import { AccountType } from "../models/enums/accountType";
import { ICohort } from "../models/ICohort";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ApplicationsState {
  applicationList: Array<IApplication>;
  currentApplication: IApplication | undefined;
  currentInternship: IInternship;
  cohortList: Array<ICohort>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateApplicationAction {
  type: "CREATE_APPLICATION_BEGIN";
  createApplication: IApplication;
}
export interface CreateApplicationSuccessAction {
  type: "CREATE_APPLICATION_SUCCESS";
}
export interface CreateApplicationErrorAction {
  type: "CREATE_APPLICATION_ERROR";
}

export interface UpdateApplicationAction {
  type: "UPDATE_APPLICATION_BEGIN";
  updateApplication: IApplication;
}
export interface UpdateApplicationSuccessAction {
  type: "UPDATE_APPLICATION_SUCCESS";
}
export interface UpdateApplicationErrorAction {
  type: "UPDATE_APPLICATION_ERROR";
}

export interface GetApplicationsAction {
  type: "GET_APPLICATIONS_BEGIN";
}
export interface GetApplicationsSuccessAction {
  type: "GET_APPLICATIONS_SUCCESS";
  data: Array<IApplication>;
}
export interface GetApplicationsErrorAction {
  type: "GET_APPLICATIONS_ERROR";
}

export interface GetApplicationAction {
  type: "GET_APPLICATION_BEGIN";
  applicationId: number;
}
export interface GetApplicationSuccessAction {
  type: "GET_APPLICATION_SUCCESS";
  data: IApplication;
}
export interface GetApplicationErrorAction {
  type: "GET_APPLICATION_ERROR";
}

export interface GetInternshipAction {
  type: "GET_INTERNSHIP_BEGIN";
  internshipId: number;
}
export interface GetInternshipSuccessAction {
  type: "GET_INTERNSHIP_SUCCESS";
  data: IInternship;
}
export interface GetInternshipErrorAction {
  type: "GET_INTERNSHIP_ERROR";
}

export interface ClearCurrentApplication {
  type: "CLEAR_CURRENT_APPLICATION";
}

export interface DeleteApplicationAction {
  type: "DELETE_APPLICATION_BEGIN";
  internshipId: number;
}
export interface DeleteApplicationSuccessAction {
  type: "DELETE_APPLICATION_SUCCESS";
  data: boolean;
}
export interface DeleteApplicationErrorAction {
  type: "DELETE_APPLICATION_ERROR";
}

export interface GetCohortsAction {
  type: "GET_COHORTS_BEGIN";
}
export interface GetCohortsSuccessAction {
  type: "GET_COHORTS_SUCCESS";
  data: Array<ICohort>;
}
export interface GetCohortsErrorAction {
  type: "GET_COHORTS_ERROR";
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | CreateApplicationAction
  | CreateApplicationSuccessAction
  | CreateApplicationErrorAction
  | UpdateApplicationAction
  | UpdateApplicationSuccessAction
  | UpdateApplicationErrorAction
  | GetApplicationsAction
  | GetApplicationsSuccessAction
  | GetApplicationsErrorAction
  | GetApplicationAction
  | GetApplicationSuccessAction
  | GetApplicationErrorAction
  | GetInternshipAction
  | GetInternshipSuccessAction
  | GetInternshipErrorAction
  | ClearCurrentApplication
  | DeleteApplicationAction
  | DeleteApplicationSuccessAction
  | DeleteApplicationErrorAction
  | GetCohortsAction
  | GetCohortsSuccessAction
  | GetCohortsErrorAction
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  // createApplication: (createApplication: ICreateApplication) => ({ type: 'CREATE_APPLICATION_BEGIN', createApplication: createApplication } as CreateApplicationAction),
  createApplicationSuccess: () => ({ type: "CREATE_APPLICATION_SUCCESS" } as CreateApplicationSuccessAction),
  createApplicationError: () => ({ type: "CREATE_APPLICATION_ERROR" } as CreateApplicationErrorAction),
  createApplication:
    (createApplication: IApplication): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      let newId = 0;
      if (appState) {
        fetch(`api/applications`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(createApplication),
        })
          .then((response) => {
            var data = response.json() as Promise<IApplication>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "CREATE_APPLICATION_SUCCESS" });
            dispatch(push(`/applications/${data.id}/attachresume`));
          });

        dispatch({ type: "CREATE_APPLICATION_BEGIN", createApplication });
      }
    },
  updateApplicationSuccess: () => ({ type: "UPDATE_APPLICATION_SUCCESS" } as UpdateApplicationSuccessAction),
  updateApplicationError: () => ({ type: "UPDATE_APPLICATION_ERROR" } as UpdateApplicationErrorAction),
  updateApplication:
    (updateApplication: IApplication): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState.user?.currentUser.accountType == AccountType.Student) {
        updateApplication.internship = {} as IInternship;
        updateApplication.student = {} as IStudent;
        updateApplication.howDidYouHear = updateApplication.howDidYouHear[0];
      }

      if (appState) {
        fetch(`api/applications`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateApplication),
        })
          .then((response) => {
            var data = response.json() as Promise<IApplication>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "UPDATE_APPLICATION_SUCCESS" });
            if (appState.user?.currentUser.accountType == AccountType.Student) {
              dispatch(push(`/applications/${data.id}/attachresume`));
            } else {
              dispatch(push(`/applications`));
            }
          });

        dispatch({ type: "UPDATE_APPLICATION_BEGIN", updateApplication });
      }
    },
  getApplications: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/applications`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createApplication)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IApplication>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_APPLICATIONS_SUCCESS", data });
        });

      dispatch({ type: "GET_APPLICATIONS_BEGIN" });
    }
  },
  getApplicationsSuccess: (data: any) =>
    ({
      type: "GET_APPLICATIONS_SUCCESS",
      data: data,
    } as GetApplicationsSuccessAction),
  getApplication:
    (applicationId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.application) {
        fetch(`api/applications/${applicationId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createApplication)
        })
          .then((response) => {
            var data = response.json() as Promise<IApplication>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_APPLICATION_SUCCESS", data });
          });

        dispatch({ type: "GET_APPLICATION_BEGIN", applicationId });
      }
    },
  getApplicationSuccess: (data: any) =>
    ({
      type: "GET_APPLICATION_SUCCESS",
      data: data,
    } as GetApplicationSuccessAction),
  clearCurrentApplication: () => ({ type: "CLEAR_CURRENT_APPLICATION" } as ClearCurrentApplication),
  getInternship:
    (internshipId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/internships/${internshipId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createInternship)
        })
          .then((response) => {
            var data = response.json() as Promise<IInternship>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_INTERNSHIP_SUCCESS", data });
          });

        dispatch({ type: "GET_INTERNSHIP_BEGIN", internshipId });
      }
    },
  getInternshipSuccess: (data: any) =>
    ({
      type: "GET_INTERNSHIP_SUCCESS",
      data: data,
    } as GetInternshipSuccessAction),
  addApplicationResume:
    (applicationId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch(push(`/applications`));
    },
  useProfileResume:
    (applicationId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/applications/${applicationId}/useprofileresume`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((response) => {
            //use response if you need
          })
          .then(() => {
            dispatch(push(`/applications/${applicationId}`));
          });
      }
    },
  deleteApplication:
    (applicationId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/applications/${applicationId}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createApplication)
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "DELETE_APPLICATION_SUCCESS", data });
          });

        //dispatch({ type: "DELETE_APPLICATION_BEGIN", applicationId });
      }
    },
  deleteApplicationSuccess: (data: any) => ({ type: "GET_INTERNSHIP_SUCCESS", data: data } as GetInternshipSuccessAction),
  getCohorts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/cohorts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createCohort)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICohort>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COHORTS_SUCCESS", data });
        });

      dispatch({ type: "GET_COHORTS_BEGIN" });
    }
  },
  getCohortsSuccess: (data: any) => ({ type: "GET_COHORTS_SUCCESS", data: data } as GetCohortsSuccessAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ApplicationsState> = (state: ApplicationsState | undefined, incomingAction: Action): ApplicationsState => {
  if (state === undefined) {
    return {
      cohortList: [],
      applicationList: [],
      currentApplication: {
        id: 0,
        cohortId: 0,
        resumeFilename: "",
        studentId: 0,
        isButtonClicked: false,
        student: {
          id: 0,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          schoolDistrict: "",
          highSchool: "",
          hasResume: false,
          gender: "",
          race: "",
          isAvenueScholar: false,
          isMCCCareerAcademy: false,
          graduationYear: 2021,
          freeOrReducedLunch: "No",
          isInterestedInMCC: false,
          resumeFilename: "",
          schoolId: 0,
          cohortId: 0,
        },
        internshipId: 0,
        internship: {
          employerId: 0,
          employer: undefined,
          id: 0,
          created: new Date("1/1/2020"),
          updated: new Date("1/1/2020"),
          internshipTitle: "",
          internshipDomain: "",
          jobDescription: "",
          eligibilityRequirements: "",
          preferredSkills: "",
          workSchedule: "",
          dressCodeRequirements: "",
          requiredTools: "",
          payPerHour: 0,
          payPerHourMax: 0,
          numberOfPositionsAvailable: 1,
          internshipLocationAddress1: "",
          internshipLocationAddress2: "",
          internshipLocationCity: "",
          internshipLocationState: "",
          internshipLocationZip: "",
          isApproved: false,
          isPublished: false,
          alreadyApplied: false,
          profileComplete: false,
          externalJobApplicationLink: "",
          youTubeVideoLink: "",
          selectedCohortIds: [],
          whoWeAre: "",
          whatYouCanExpect: "",
          summerOnly: false,
          internshipLength: ""
        },
        howDidYouHear: "",
        whatInterestsYou: "",
        whyStrongestCandidate: "",
        immediateCareerGoals: "",
        longTermCareerGoals: "",
        canSatisfyRequirements: true,
        canSatisfyRequirementsExplaination: "",
        applicationStatus: ApplicationStatus.Applied,
        declineReason: "",
        howDidYouHearOther: "",
      },
      currentInternship: {
        employerId: 0,
        employer: undefined,
        id: 0,
        created: new Date("1/1/2020"),
        updated: new Date("1/1/2020"),
        internshipTitle: "",
        internshipDomain: "",
        jobDescription: "",
        eligibilityRequirements: "",
        preferredSkills: "",
        workSchedule: "",
        dressCodeRequirements: "",
        requiredTools: "",
        payPerHour: 0,
        payPerHourMax: 0,
        numberOfPositionsAvailable: 1,
        internshipLocationAddress1: "",
        internshipLocationAddress2: "",
        internshipLocationCity: "",
        internshipLocationState: "",
        internshipLocationZip: "",
        isApproved: false,
        isPublished: false,
        alreadyApplied: false,
        profileComplete: false,
        externalJobApplicationLink: "",
        youTubeVideoLink: "",
        selectedCohortIds: [],
        whoWeAre: "",
        whatYouCanExpect: "",
        summerOnly: false,
        internshipLength: ""

      },
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "CREATE_APPLICATION_BEGIN":
      return {
        ...state,
      };
    case "GET_APPLICATIONS_SUCCESS":
      return {
        ...state,
        applicationList: (action as GetApplicationsSuccessAction).data,
      };
    case "GET_APPLICATION_SUCCESS":
      return {
        ...state,
        currentApplication: (action as GetApplicationSuccessAction).data,
      };
    case "CLEAR_CURRENT_APPLICATION":
      return {
        ...state,
        currentApplication: {
          cohortId: 0,
          id: 0,
          resumeFilename: "",
          studentId: 0,
          isButtonClicked: false,
          student: {
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            schoolDistrict: "",
            highSchool: "",
            hasResume: false,
            gender: "",
            race: "",
            isAvenueScholar: false,
            isMCCCareerAcademy: false,
            graduationYear: 2021,
            freeOrReducedLunch: "No",
            isInterestedInMCC: false,
            resumeFilename: "",
            schoolId: 0,
          cohortId: 0,
          },
          internshipId: 0,
          internship: {
            employerId: 0,
            employer: undefined,
            id: 0,
            created: new Date("1/1/2020"),
            updated: new Date("1/1/2020"),
            internshipTitle: "",
            internshipDomain: "",
            jobDescription: "",
            eligibilityRequirements: "",
            preferredSkills: "",
            workSchedule: "",
            dressCodeRequirements: "",
            requiredTools: "",
            payPerHour: 0,
            payPerHourMax: 0,
            numberOfPositionsAvailable: 1,
            internshipLocationAddress1: "",
            internshipLocationAddress2: "",
            internshipLocationCity: "",
            internshipLocationState: "",
            internshipLocationZip: "",
            isApproved: false,
            isPublished: false,
            alreadyApplied: false,
            profileComplete: false,
            externalJobApplicationLink: "",
            youTubeVideoLink: "",
            selectedCohortIds: [],
            whoWeAre: "",
            whatYouCanExpect: "",
            summerOnly: false,
            internshipLength: ""

          },
          howDidYouHear: "",
          whatInterestsYou: "",
          whyStrongestCandidate: "",
          immediateCareerGoals: "",
          longTermCareerGoals: "",
          canSatisfyRequirements: true,
          canSatisfyRequirementsExplaination: "",
          applicationStatus: ApplicationStatus.Applied,
          declineReason: "",
          howDidYouHearOther: "",
        },
        currentInternship: {
          employerId: 0,
          employer: undefined,
          id: 0,
          created: new Date("1/1/2020"),
          updated: new Date("1/1/2020"),
          internshipTitle: "",
          internshipDomain: "",
          jobDescription: "",
          eligibilityRequirements: "",
          preferredSkills: "",
          workSchedule: "",
          dressCodeRequirements: "",
          requiredTools: "",
          payPerHour: 0,
          payPerHourMax: 0,
          numberOfPositionsAvailable: 1,
          internshipLocationAddress1: "",
          internshipLocationAddress2: "",
          internshipLocationCity: "",
          internshipLocationState: "",
          internshipLocationZip: "",
          isApproved: false,
          isPublished: false,
          alreadyApplied: false,
          profileComplete: false,
          externalJobApplicationLink: "",
          youTubeVideoLink: "",
          selectedCohortIds: [],
          whoWeAre: "",
          whatYouCanExpect: "",
          summerOnly: false,
          internshipLength: ""

        },
      };
    case "GET_INTERNSHIP_SUCCESS":
      return {
        ...state,
        currentInternship: (action as GetInternshipSuccessAction).data,
      };
    case "DELETE_APPLICATION_SUCCESS":
      return {  
        ...state,
        currentApplication: undefined,
      };
    case "GET_COHORTS_SUCCESS":
      return {
        ...state,
        cohortList: (action as GetCohortsSuccessAction).data,
      };
    default:
      return state;
  }
};
