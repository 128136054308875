import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { IStudentSchoolCount } from "../../../models/IStudentSchoolCount";
import { useEffect, useState } from "react";

type StudentSchoolCountsProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;

function StudentSchoolCounts(props: StudentSchoolCountsProps) {
  let dt: DataTable | null | undefined;
  const [studentSchoolCounts, setStudentSchoolCounts] = useState(props.studentSchoolCounts);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);


  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const school = query.get('HighSchool')
    setGlobalFilter(school ?? "");
    props.getStudentSchoolCounts();
    props.getCohorts();
  }, []);

  useEffect(() => {
    setStudentSchoolCounts(props.studentSchoolCounts);
  }, [props.studentSchoolCounts]);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        value={globalFilter ?? ''}
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />

    </div>
  );

  return (
    <React.Fragment>
      <h1>Student/School Counts</h1>

      <DataTable
        ref={(el) => (dt = el)}
        value={studentSchoolCounts}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
      >
        <Column field="schoolDistrict" header="School District" sortable={true} />
        <Column field="highSchool" header="High School" sortable={true} />
        <Column field="cohort" header="Cohort" sortable/>
        <Column field="numberOfStudents" header="Number Of Students" sortable={true} />
        <Column field="applicantCount" header="Number Of Applicants" sortable={true} />
        <Column field="pendingApplicationCount" header="Number Of Incomplete Applications" sortable={true} />
        <Column field="applicationCount" header="Number Of Applications" sortable={true} />
        <Column field="hiredCount" header="Number Of Hires" sortable={true} />
        <Column field="cohortId" style={{ display: 'none' }} header="Cohort" />

      </DataTable>
    </React.Fragment>
  );


  function handleRowClick(e: any) {
    console.log(e);
    props.history.push(`/reports/studentApplications?HighSchool=${e.data.highSchool}`);
  }
}

export default connect((state: ApplicationState) => state.report, ReportStore.actionCreators)(StudentSchoolCounts as any);
