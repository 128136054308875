
export enum ApplicationStatus {
   Applied = 0,
   UnderReview = 1,
   SelectedForInterview = 2,
   Declined = 3,
   OfferOut = 4,
   OfferAccepted = 5,
   OfferDeclined = 6,
   PendingSubmission = 10
}