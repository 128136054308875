import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Alert } from "reactstrap";
import { useEffect, useState } from "react";

type SignupProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Signup(props: SignupProps) {
  const [createUser, setCreateUser] = useState({
    Username: "",
    Password: "",
    ConfirmPassword: "",
    FirstName: "",
    LastName: "",
    Phone: "",
    Email: "",
    SignupCode: "",
    AccountType: AccountType.District,
    EmployerId: 0,
  } as ICreateUser);
   const [employers, setEmployers] = useState([{ label: "Test", value: "" }]);

    useEffect(() => {
        props.getEmployers();
    }, []);

    useEffect(() => {
        const employers = props.employerList.map((employer) => {
            return { label: employer.employerName, value: employer.id.toString() };
        });
        setEmployers(employers);
    }, [props.employerList]);


  return (
    <React.Fragment>
      <Card title="Create an Avenue Scholars Interns Account" subTitle="">
        <label>Username:</label>
        <br />
        <InputText
          name="username"
          value={createUser.Username}
          onChange={(e) => setCreateUser({ ...createUser, Username: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Password:</label>
        <br />
        <Password
          feedback={false}
          value={createUser.Password}
          onChange={(e) => setCreateUser({ ...createUser, Password: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />


        <label>Confirm Password:</label>
        <br />
        <Password
          feedback={false}
          value={createUser.ConfirmPassword}
          onChange={(e) => setCreateUser({ ...createUser, ConfirmPassword: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>First Name:</label>
        <br />
        <InputText
          name="first"
          value={createUser.FirstName}
          onChange={(e) => setCreateUser({ ...createUser, FirstName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Last Name:</label>
        <br />
        <InputText
          name="last"
          value={createUser.LastName}
          onChange={(e) => setCreateUser({ ...createUser, LastName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Email:</label>
        <br />
        <InputText name="email" value={createUser.Email} onChange={(e) => setCreateUser({ ...createUser, Email: (e.target as unknown as HTMLTextAreaElement).value })} />
        <br />
        <br />

        <label>Phone:</label>
        <br />
        <InputText name="phone" value={createUser.Phone} onChange={(e) => setCreateUser({ ...createUser, Phone: (e.target as unknown as HTMLTextAreaElement).value })} />
        <br />
        <br />

        <label>User Type:</label>
        <br />
        <Dropdown
          value={createUser.AccountType}
          options={[
            { label: "Student", value: "2" },
            { label: "Employer", value: "1" },
            { label: "Admin", value: "0" },
            { label: "District", value: "3" },
          ]}
          onChange={(e) => {
            setCreateUser({ ...createUser, AccountType: e.value });
          }}
          placeholder="Select an Account Type"
        />
        <br />
        <br />
        {createUser.AccountType == AccountType.Student && (<div>
          <label>Signup Code:</label>
          <div className="guideText">At this time, the Avenue Scholars Interns portal is only open to students who are participating in the Avenue Scholars program. Students who are in Avenue Scholars will need to enter the code you were provided below</div>

          <br />
          <InputText name="code" value={createUser.SignupCode} onChange={(e) => setCreateUser({ ...createUser, SignupCode: (e.target as unknown as HTMLTextAreaElement).value })} />
          <br />
          <br />
              </div>)}

        {createUser.AccountType == AccountType.Employer && (<div>
            <label>Employer:</label>
                  <br />
                  <Dropdown
                      value={createUser.EmployerId}
                      options={employers}
                      onChange={(e) => {
                          setCreateUser({ ...createUser, EmployerId: e.value });
                      }}
                      placeholder="Select An Employer"
                  />
                  <br />
            <br />
        </div>)}

        <Button
          label="Sign Up"
          onClick={() => {
            if (createUser.Password === createUser.ConfirmPassword) {
              if (createUser.AccountType != AccountType.Student || createUser.SignupCode === 'aveonly2024') {
                props.createUser(createUser);
              }
              else {
                alert("You must enter the Signup Code provided to you by Avenue Scholars.");
              }
            }
            else {
              alert("Passwords must match");
            }
          }}
        />

        <br />
        <br />
        <br />
        <p className="errorText">{props.createUserErrorMessage}</p>
      </Card>
    </React.Fragment>
  );
}


export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Signup as any);
