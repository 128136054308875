import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as CohortStore from "../../../store/cohort";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ICohort } from "../../../models/ICohort";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { NavLink } from "reactstrap";
import { equal } from "assert";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { InputNumber } from "primereact/inputnumber";

type CohortEditProps = CohortStore.CohortState & typeof CohortStore.actionCreators & RouteComponentProps<{}>;

function CohortEdit(props: CohortEditProps) {
  const [currentCohort, setCurrentCohort] = useState({ id: 0, name: '', description: ''} as ICohort);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  useEffect(() => {
    const selectedCohortId = (props.match.params as any).cohortId;
    
      if (selectedCohortId > 0) {
          props.getCohort(selectedCohortId);
      }
      else {
          setStartTime(new Date());
          setEndTime(new Date());
      }
  }, []);

  useEffect(() => {
    setCurrentCohort(props.selectedCohort as ICohort);
      var start = props.selectedCohort?.start?.toString() ?? '';
      if (start == '') {
          start = new Date().toString() + 'Z';
      }
      else {
          start = start + 'Z';
      }

    setStartTime(new Date(new Date(start).toLocaleString()));
    

      var end = props.selectedCohort?.end?.toString() ?? '';
      if (end == '') {
          end = new Date().toString() + 'Z';
      }
      else {
          end = end + 'Z';
      } 

    setEndTime(new Date(new Date(end).toLocaleString()));

  }, [props.selectedCohort]);

  useEffect(() => {
    setCurrentCohort({...currentCohort as ICohort, start: startTime});
  }, [startTime]);

  useEffect(() => {
    setCurrentCohort({...currentCohort as ICohort, end: endTime});
  }, [endTime]);

  
  const notify: any = () => toast.success("Settings Saved!");

  return (
    <React.Fragment>
       <Button
          label="Back To List"
          onClick={() => {
            props.history.push(`/cohorts`);
          }}
        />
      <Card title="Edit Cohort" subTitle="">
        <label>Cohort name:</label>
        <br />
        <InputText
          name="name"
          value={currentCohort?.name}
          onChange={(e) => setCurrentCohort({ ...currentCohort as ICohort, name: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

         <label>Description:</label>
        <br />
        <InputText
          name="description"
          value={currentCohort?.description}
          onChange={(e) => setCurrentCohort({ ...currentCohort as ICohort, description: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

         <label>Graduation Year:</label>
        <br />
        <InputNumber
          min={0}
          max={2050}
          useGrouping={false}
          name="graduationYear"
          value={currentCohort?.graduationYear}
          onChange={(e) => setCurrentCohort({ ...currentCohort as ICohort, graduationYear: e.value })}
        />
        <br />
        <br />

        <label>Start:</label>
        <br />
        <Calendar id="start" value={startTime} onChange={(e) => setCurrentCohort({ ...currentCohort as ICohort, start: e.value as Date })} showTime hourFormat="12" />
        <br />
        <br />

        <label>End:</label>
        <br />
        <Calendar id="end" value={endTime} onChange={(e) => setCurrentCohort({ ...currentCohort as ICohort, end: e.value as Date })} showTime hourFormat="12"/>
        <br />
        <br />
{/* 
        <label>First Name:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentCohort?.firstName}
          onChange={(e) => setCurrentCohort({ ...currentCohort, firstName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Last Name:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentCohort?.lastName}
          onChange={(e) => setCurrentCohort({ ...currentCohort, lastName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Email:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentCohort?.email}
          onChange={(e) => setCurrentCohort({ ...currentCohort, email: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        <label>Phone:</label>
        <br />
        <InputText
          disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
          name="username"
          value={currentCohort?.phone}
          onChange={(e) => setCurrentCohort({ ...currentCohort, phone: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />

        {currentCohort?.accountType == AccountType.Employer ? (
          <div>
            <label>Employer:</label>
            <br />
            <Dropdown
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              value={currentCohort?.employerId ? currentCohort?.employerId.toString() : 0}
              options={employers}
              onChange={(e) => {
                setCurrentCohort({ ...currentCohort, employerId: e.value });
              }}
              placeholder="Select An Employer"
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}

        {currentCohort?.accountType == AccountType.District && (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            <label>District:</label>
            <br />
            <Dropdown
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              value={currentCohort?.districtId ? currentCohort?.districtId.toString() : 0}
              options={districts}
              onChange={(e) => {
                setCurrentCohort({ ...currentCohort, districtId: e.value });
              }}
              placeholder="Select A District"
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}

        {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            <label>Approved:</label>
            <br />
            <InputSwitch
              disabled={(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.District}
              checked={currentCohort?.approved}
              onChange={(e) => setCurrentCohort({ ...currentCohort, approved: e.value })}
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )} */}

        <Button
          label="Save"
          onClick={() => {
            props.updateCohort(currentCohort as ICohort);
            notify();
          }}
        />
        {(currentCohort?.id ?? 0) > 0 && (
          <Button
            label="Archive"
            className="p-button-danger"
            onClick={() => {
              props.deleteCohort(currentCohort?.id ?? 0);
              props.history.push(`/cohorts`);
            }}
          />
        )}
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.cohort, CohortStore.actionCreators)(CohortEdit as any);
