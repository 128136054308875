import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ITokenResult } from '../models/ITokenResult';
import { IStudent } from '../models/IStudent';
import { push } from 'react-router-redux';
import { RouterAction } from 'connected-react-router';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface StudentState {
    studentList: Array<IStudent>,
    currentStudent?: IStudent
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateStudentAction { type: 'CREATE_STUDENT_BEGIN', createStudent: IStudent }
export interface CreateStudentSuccessAction { type: 'CREATE_STUDENT_SUCCESS' }
export interface CreateStudentErrorAction { type: 'CREATE_STUDENT_ERROR' }

export interface UpdateStudentAction { type: 'UPDATE_STUDENT_BEGIN', updateStudent: IStudent }
export interface UpdateStudentSuccessAction { type: 'UPDATE_STUDENT_SUCCESS' }
export interface UpdateStudentErrorAction { type: 'UPDATE_STUDENT_ERROR' }

export interface GetStudentsAction { type: 'GET_STUDENTS_BEGIN' }
export interface GetStudentsSuccessAction { type: 'GET_STUDENTS_SUCCESS', data: Array<IStudent> }
export interface GetStudentsErrorAction { type: 'GET_STUDENTS_ERROR' }

export interface GetStudentAction { type: 'GET_STUDENT_BEGIN', studentId: number }
export interface GetStudentSuccessAction { type: 'GET_STUDENT_SUCCESS', data: IStudent }
export interface GetStudentErrorAction { type: 'GET_STUDENT_ERROR' }

export interface ClearCurrentStudent { type: 'CLEAR_CURRENT_STUDENT' }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = CreateStudentAction | CreateStudentSuccessAction | CreateStudentErrorAction |
    UpdateStudentAction | UpdateStudentSuccessAction | UpdateStudentErrorAction |
    GetStudentsAction | GetStudentsSuccessAction | GetStudentsErrorAction |
    GetStudentAction | GetStudentSuccessAction | GetStudentErrorAction |
    ClearCurrentStudent | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    // createStudent: (createStudent: ICreateStudent) => ({ type: 'CREATE_STUDENT_BEGIN', createStudent: createStudent } as CreateStudentAction),
    createStudentSuccess: () => ({ type: 'CREATE_STUDENT_SUCCESS' } as CreateStudentSuccessAction),
    createStudentError: () => ({ type: 'CREATE_STUDENT_ERROR' } as CreateStudentErrorAction),
    createStudent: (createStudent: IStudent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/students`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(createStudent)
            })
                .then(response => {
                    var data = response.json() as Promise<ITokenResult>;
                    return data;
                })
                .then(() => {
                    dispatch({ type: 'CREATE_STUDENT_SUCCESS' });
                    dispatch(push('/students'));
                });

            dispatch({ type: 'CREATE_STUDENT_BEGIN', createStudent });
        }
    },
    updateStudentSuccess: () => ({ type: 'UPDATE_STUDENT_SUCCESS' } as UpdateStudentSuccessAction),
    updateStudentError: () => ({ type: 'UPDATE_STUDENT_ERROR' } as UpdateStudentErrorAction),
    updateStudent: (updateStudent: IStudent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/students`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(updateStudent)
            })
                .then(response => {
                    var data = response.json() as Promise<ITokenResult>;
                    return data;
                })
                .then(() => {
                    dispatch({ type: 'UPDATE_STUDENT_SUCCESS' });
                    dispatch(push(`/students/${updateStudent.id}`));
                });

            dispatch({ type: 'UPDATE_STUDENT_BEGIN', updateStudent });
        }
    },
    getStudents: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.student) {
            fetch(`api/students`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                //body: JSON.stringify(createStudent)
            })
                .then(response => {
                    var data = response.json() as Promise<Array<IStudent>>;
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'GET_STUDENTS_SUCCESS', data });
                });

            dispatch({ type: 'GET_STUDENTS_BEGIN' });
        }
    },
    getStudentsSuccess: (data: any) => ({ type: 'GET_STUDENTS_SUCCESS', data: data } as GetStudentsSuccessAction),
    getStudent: (studentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.student) {
            fetch(`api/students/${studentId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                //body: JSON.stringify(createStudent)
            })
                .then(response => {
                    var data = response.json() as Promise<IStudent>;
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'GET_STUDENT_SUCCESS', data });
                });

            dispatch({ type: 'GET_STUDENT_BEGIN', studentId });
        }
    },
    getStudentSuccess: (data: any) => ({ type: 'GET_STUDENT_SUCCESS', data: data } as GetStudentSuccessAction),
    clearCurrentStudent: () => ({ type: 'CLEAR_CURRENT_STUDENT' } as ClearCurrentStudent)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<StudentState> = (state: StudentState | undefined, incomingAction: Action): StudentState => {
    if (state === undefined) {
        return {
            studentList: [],
            currentStudent: undefined
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATE_STUDENT_BEGIN':
            return {
                ...state
            };
        case 'GET_STUDENTS_SUCCESS':
            return {
                ...state,
                studentList: (action as GetStudentsSuccessAction).data
            };
        case 'GET_STUDENT_SUCCESS':
            return {
                ...state,
                currentStudent: (action as GetStudentSuccessAction).data
            };
        case 'CLEAR_CURRENT_STUDENT':
            return {
                ...state,
                currentStudent: undefined
            };
        default:
            return state;
    }
};
