import { IApplicantsPerRole } from "./../models/IApplicantsPerRole";
import { IStudentSchoolCount } from "./../models/IStudentSchoolCount";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import { IStudent } from "../models/IStudent";
import { ICohort } from "../models/ICohort";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportState {
  studentSchoolCounts: Array<IStudentSchoolCount>;
  applicantsPerRole: Array<IApplicantsPerRole>;
  yoyPartnerPerformance: Array<any>;
  studentsInRoles: Array<any>;
  studentInformation: Array<IStudent>;
  cohortList: Array<ICohort>;
  // studentsInRoles: Array<IStudentsInRoles>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetStudentSchoolCountReportAction {
  type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_BEGIN";
}
export interface GetStudentSchoolCountReportSuccessAction {
  type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_SUCCESS";
  data: Array<IStudentSchoolCount>;
}
export interface GetStudentSchoolCountReportErrorAction {
  type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_ERROR";
}

export interface GetApplicantsPerRoleReportAction {
  type: "GET_REPORT_APPLICANTS_PER_ROLE_BEGIN";
}
export interface GetApplicantsPerRoleReportSuccessAction {
  type: "GET_REPORT_APPLICANTS_PER_ROLE_SUCCESS";
  data: Array<IApplicantsPerRole>;
}
export interface GetApplicantsPerRoleReportErrorAction {
  type: "GET_REPORT_APPLICANTS_PER_ROLE_ERROR";
}

export interface GetStudentsInRolesReportAction {
  type: "GET_REPORT_STUDENTS_IN_ROLES_BEGIN";
}
export interface GetStudentsInRolesReportSuccessAction {
  type: "GET_REPORT_STUDENTS_IN_ROLES_SUCCESS";
  data: Array<any>;
}
export interface GetStudentsInRolesReportErrorAction {
  type: "GET_REPORT_STUDENTS_IN_ROLES_ERROR";
}


export interface GetStudentApplicationsReportAction {
  type: "GET_REPORT_STUDENT_APPLICATIONS_BEGIN";
}
export interface GetStudentApplicationsReportSuccessAction {
  type: "GET_REPORT_STUDENT_APPLICATIONS_SUCCESS";
  data: Array<any>;
}
export interface GetStudentApplicationsReportErrorAction {
  type: "GET_REPORT_STUDENT_APPLICATIONS_ERROR";
}


export interface GetStudentInformationReportAction {
  type: "GET_REPORT_STUDENT_INFORMATION_BEGIN";
}
export interface GetStudentInformationReportSuccessAction {
  type: "GET_REPORT_STUDENT_INFORMATION_SUCCESS";
  data: Array<any>;
}
export interface GetStudentInformationReportErrorAction {
  type: "GET_REPORT_STUDENT_INFORMATION_ERROR";
}

export interface GetYoYPartnerPerformanceReportAction {
  type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_BEGIN";
}
export interface GetYoYPartnerPerformanceReportSuccessAction {
  type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_SUCCESS";
  data: Array<any>;
}
export interface GetYoYPartnerPerformanceReportErrorAction {
  type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_ERROR";
}

export interface GetCohortsAction {
  type: "GET_COHORTS_BEGIN";
}
export interface GetCohortsSuccessAction {
  type: "GET_COHORTS_SUCCESS";
  data: Array<ICohort>;
}
export interface GetCohortsErrorAction {
  type: "GET_COHORTS_ERROR";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | GetStudentSchoolCountReportAction
  | GetStudentSchoolCountReportSuccessAction
  | GetStudentSchoolCountReportErrorAction

  | GetApplicantsPerRoleReportAction
  | GetApplicantsPerRoleReportSuccessAction
  | GetApplicantsPerRoleReportErrorAction

  | GetStudentsInRolesReportAction
  | GetStudentsInRolesReportSuccessAction
  | GetStudentsInRolesReportErrorAction

  | GetStudentApplicationsReportAction
  | GetStudentApplicationsReportSuccessAction
  | GetStudentApplicationsReportErrorAction

  | GetStudentInformationReportAction
  | GetStudentInformationReportSuccessAction
  | GetStudentInformationReportErrorAction

  | GetYoYPartnerPerformanceReportAction
  | GetYoYPartnerPerformanceReportSuccessAction
  | GetYoYPartnerPerformanceReportErrorAction

  | GetCohortsAction
  | GetCohortsSuccessAction
  | GetCohortsErrorAction
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getStudentSchoolCounts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/studentschoolcounts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IStudentSchoolCount>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_BEGIN" });
    }
  },
  getStudentSchoolCountsSuccess: (data: any) => ({ type: "GET_REPORT_SCHOOL_STUDENT_COUNTS_SUCCESS", data: data } as GetStudentSchoolCountReportSuccessAction),

  getApplicantsPerRole: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/applicantsperrole`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IApplicantsPerRole>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_APPLICANTS_PER_ROLE_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_APPLICANTS_PER_ROLE_BEGIN" });
    }
  },
  getApplicantsPerRoleSuccess: (data: any) => ({ type: "GET_REPORT_APPLICANTS_PER_ROLE_SUCCESS", data: data } as GetApplicantsPerRoleReportSuccessAction),

  getStudentsInRoles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/studentsinroles`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<any>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_STUDENTS_IN_ROLES_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_STUDENTS_IN_ROLES_BEGIN" });
    }
  },
  getStudentsInRolesSuccess: (data: any) => ({ type: "GET_REPORT_STUDENTS_IN_ROLES_SUCCESS", data: data } as GetStudentsInRolesReportSuccessAction),

  getStudentApplications: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/studentapplications`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<any>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_STUDENT_APPLICATIONS_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_STUDENT_APPLICATIONS_BEGIN" });
    }
  },
  getStudentApplicationsRolesSuccess: (data: any) => ({ type: "GET_REPORT_STUDENT_APPLICATIONS_SUCCESS", data: data } as GetStudentApplicationsReportSuccessAction),


  getStudentInformation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/studentinformation`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<any>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_STUDENT_INFORMATION_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_STUDENT_INFORMATION_BEGIN" });
    }
  },
  getStudentInformationSuccess: (data: any) => ({ type: "GET_REPORT_STUDENT_INFORMATION_SUCCESS", data: data } as GetStudentInformationReportSuccessAction),


  getYoYPartnerPerformance: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/reports/yoypartnerperformance`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createReport)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<any>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_SUCCESS", data });
        });

      dispatch({ type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_BEGIN" });
    }
  },
  getYoYPartnerPerformanceSuccess: (data: any) => ({ type: "GET_REPORT_YOY_PARTNER_PERFORMANCE_SUCCESS", data: data } as GetYoYPartnerPerformanceReportSuccessAction),
  getCohorts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/cohorts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createCohort)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICohort>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COHORTS_SUCCESS", data });
        });

      dispatch({ type: "GET_COHORTS_BEGIN" });
    }
  },
  getCohortsSuccess: (data: any) => ({ type: "GET_COHORTS_SUCCESS", data: data } as GetCohortsSuccessAction),
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ReportState> = (state: ReportState | undefined, incomingAction: Action): ReportState => {
  if (state === undefined) {
    return {
      studentSchoolCounts: [],
      applicantsPerRole: [],
      studentsInRoles: [],
      yoyPartnerPerformance: [],
      studentInformation: [],
      cohortList: [],
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_REPORT_SCHOOL_STUDENT_COUNTS_SUCCESS":
      return {
        ...state,
        studentSchoolCounts: (action as GetStudentSchoolCountReportSuccessAction).data,
      };
    case "GET_REPORT_APPLICANTS_PER_ROLE_SUCCESS":
      return {
        ...state,
        applicantsPerRole: (action as GetApplicantsPerRoleReportSuccessAction).data,
      };
    case "GET_REPORT_STUDENTS_IN_ROLES_SUCCESS":
      return {
        ...state,
        studentsInRoles: (action as GetStudentsInRolesReportSuccessAction).data,
      };
      case "GET_REPORT_STUDENT_APPLICATIONS_SUCCESS":
      return {
        ...state,
        studentsInRoles: (action as GetStudentApplicationsReportSuccessAction).data,
      };
    case "GET_REPORT_STUDENT_INFORMATION_SUCCESS":
    return {
      ...state,
      studentInformation: (action as GetStudentInformationReportSuccessAction).data,
    };
    case "GET_REPORT_YOY_PARTNER_PERFORMANCE_SUCCESS":
      return {
        ...state,
        yoyPartnerPerformance: (action as GetYoYPartnerPerformanceReportSuccessAction).data,
      };
    case "GET_COHORTS_SUCCESS":
      return {
        ...state,
        cohortList: (action as GetCohortsSuccessAction).data,
      };
    default:
      return state;
  }
};
