import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as ApplicationsStore from "../../../store/application";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IInternship } from "../../../models/IInternship";
import { Editor } from "primereact/editor";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { IApplication } from "../../../models/IApplication";
import { ApplicationStatus } from "../../../models/enums/applicationStatus";
import { toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";
import Axios from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";

type ApplicationViewProps = ApplicationsStore.ApplicationsState & typeof ApplicationsStore.actionCreators & RouteComponentProps<{}>;

function ApplicationView(props: ApplicationViewProps) {
  // constructor(props: any) {
  //   super(props);

  //   state = {
  //     id: 0,
  //     studentId: 0,
  //     student: {
  //       id: 0,
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       phone: "",
  //       schoolDistrict: "",
  //       highSchool: "",
  //       hasResume: false,
  //     },
  //     internshipId: 0,
  //     internship: {
  //       employerId: 0,
  //       employer: undefined,
  //       id: 0,
  //       created: new Date("1/1/2020"),
  //       updated: new Date("1/1/2020"),
  //       internshipTitle: "",
  //       internshipDomain: "",
  //       jobDescription: "",
  //       eligibilityRequirements: "",
  //       preferredSkills: "",
  //       workSchedule: "",
  //       dressCodeRequirements: "",
  //       requiredTools: "",
  //       payPerHour: 0,
  //       payPerHourMax: 0,
  //       numberOfPositionsAvailable: 1,
  //       internshipLocationAddress1: "",
  //       internshipLocationAddress2: "",
  //       internshipLocationCity: "",
  //       internshipLocationState: "",
  //       internshipLocationZip: "",
  //       isApproved: false,
  //       isPublished: false,
  //       alreadyApplied: false,
  //       youTubeVideoLink: ""
  //     },
  //     howDidYouHear: "",
  //     whatInterestsYou: "",
  //     whyStrongestCandidate: "",
  //     immediateCareerGoals: "",
  //     longTermCareerGoals: "",
  //     canSatisfyRequirements: true,
  //     canSatisfyRequirementsExplaination: "",
  //     applicationStatus: ApplicationStatus.Applied,
  //     declineReason: "",
  //   } as IApplication;
  // }

  const [currentApplication, setCurrentApplication] = useState(props.currentApplication);

  useEffect(() => {
    const currentApplicationId = (props.match.params as any).applicationId;
    if (currentApplicationId > 0) {
      props.getApplication(currentApplicationId);
    }
  }, []);

  useEffect(() => {
    setCurrentApplication(props.currentApplication);
  }, [props.currentApplication]);

  // componentDidMount() {

  //   if (currentApplicationId > 0) {
  //     props.getApplication(currentApplicationId);

  //     setTimeout(() => {
  //       setState(props.currentApplication as IApplication);
  //       setState({ id: currentApplicationId });
  //     }, 950);
  //   } else {
  //     setState({
  //       id: 0,
  //       studentId: 0,
  //       student: {
  //         id: 0,
  //         firstName: "",
  //         lastName: "",
  //         email: "",
  //         phone: "",
  //         schoolDistrict: "",
  //         highSchool: "",
  //         hasResume: false,
  //         gender: "",
  //         race: "",
  //         isAvenueScholar: false,
  //         isMCCCareerAcademy: false,
  //         graduationYear: 2021,
  //         freeOrReducedLunch: "No",
  //         isInterestedInMCC: false,
  //         resumeFilename: "",
  //         schoolId: 0,
  //       },
  //       internshipId: 0,
  //       internship: {
  //         employerId: 0,
  //         employer: undefined,
  //         id: 0,
  //         created: new Date("1/1/2020"),
  //         updated: new Date("1/1/2020"),
  //         internshipTitle: "",
  //         internshipDomain: "",
  //         jobDescription: "",
  //         eligibilityRequirements: "",
  //         preferredSkills: "",
  //         workSchedule: "",
  //         dressCodeRequirements: "",
  //         requiredTools: "",
  //         payPerHour: 0,
  //         payPerHourMax: 0,
  //         numberOfPositionsAvailable: 1,
  //         internshipLocationAddress1: "",
  //         internshipLocationAddress2: "",
  //         internshipLocationCity: "",
  //         internshipLocationState: "",
  //         internshipLocationZip: "",
  //         isApproved: false,
  //         isPublished: false,
  //         alreadyApplied: false,
  //         profileComplete: false,
  //         externalJobApplicationLink: "",
  //         youTubeVideoLink: ""
  //       },
  //       howDidYouHear: "",
  //       whatInterestsYou: "",
  //       whyStrongestCandidate: "",
  //       immediateCareerGoals: "",
  //       longTermCareerGoals: "",
  //       canSatisfyRequirements: true,
  //       canSatisfyRequirementsExplaination: "",
  //       applicationStatus: ApplicationStatus.Applied,
  //       declineReason: "",
  //     });
  //   }
  // }

  const notify: any = () => toast.success("Application Status Updated!");
  const statuses = [
    { label: "Applied", value: 0 },
    { label: "Under Review", value: 1 },
    { label: "Selected For Interview", value: 2 },
    { label: "Declined", value: 3 },
    { label: "Offer Out", value: 4 },
    { label: "Offer Accepted", value: 5 },
    { label: "Offer Declined", value: 6 },
    { label: "Pending Submission", value: 10 },
  ];

  const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;

  function downloadResume(id: number, filename: string) {
    Axios.get("./api/applications/" + id + "/resume", {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      var newfilename = "";
      var disposition = res.headers["content-disposition"];
      newfilename = disposition.split("filename=")[1].split(";")[0].replaceAll('"', "");
      fileDownload(res.data, newfilename);
    });
  }

  return (
    <React.Fragment>
      {currentType === "2" ? (
        <Button
          label="Back To List"
          onClick={() => {
            props.history.push(`/students/${props.currentApplication?.studentId}/applications`);
          }}
        />
      ) : (
        <Button
          label="Back To List"
          onClick={() => {
            props.history.push(`/applications`);
          }}
        />
      )}

      <Card title="" subTitle="" className="preview-card">
              {props.currentApplication?.internship.employerId && (
                  <img style={{ maxHeight: "300px", maxWidth: "500px" }} src={`./api/employers/${props.currentApplication?.internship.employerId}/logo`}></img>
              )}
        <br />
        <br />
        <h1>{props.currentApplication?.internship.employer ? props.currentApplication?.internship.employer.employerName : ""}</h1>
        <br />
        {currentApplication?.internship.youTubeVideoLink && (
          <div>
            <iframe
              width="560"
              height="315"
              src={`${currentApplication.internship.youTubeVideoLink}`}
              //src={`https://www.youtube.com/embed/${currentApplication.internship.youTubeVideoLink}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <br />
          </div>
        )}
        <label className="preview-label">Internship Title:</label>&nbsp;
        {props.currentApplication?.internship.internshipTitle}
        <br />
        <br />
        <label className="preview-label">Internship Domain:</label>&nbsp;
        {props.currentApplication?.internship.internshipDomain}
        <br />
        <br />
        <label className="preview-label">Job Description:</label>&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.jobDescription ? props.currentApplication?.internship.jobDescription : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">Eligibility Requirements:</label>
        &nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.eligibilityRequirements ? props.currentApplication?.internship.eligibilityRequirements : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">Preferred Skills:</label>&nbsp;
        {/* {props.currentApplication?.internship.preferredSkills} */}
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.preferredSkills ? props.currentApplication?.internship.preferredSkills : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">Work Schedule:</label>&nbsp;
        {/* {props.currentApplication?.internship.workSchedule} <br /> */}
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.workSchedule ? props.currentApplication?.internship.workSchedule : "",
          }}
        />
        <br />
        <label className="preview-label">Dress Code Requirements:</label>
        &nbsp;
        {/* {props.currentApplication?.internship.dressCodeRequirements} */}
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.dressCodeRequirements ? props.currentApplication?.internship.dressCodeRequirements : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">Required Tools:</label>&nbsp;
        {/* {props.currentApplication?.internship.requiredTools} */}
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.internship.requiredTools ? props.currentApplication?.internship.requiredTools : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">Pay Per Hour Minimum:</label>&nbsp; ${props.currentApplication?.internship.payPerHour}
        <br />
        <br />
        <label className="preview-label">Pay Per Hour Maximum:</label>&nbsp; ${props.currentApplication?.internship.payPerHourMax}
        <br />
        <br />
        <label className="preview-label">Number Of Positions Available:</label>
        &nbsp;
        {props.currentApplication?.internship.numberOfPositionsAvailable}
        <br />
        <br />
        <label className="preview-label">Internship Location:</label>
        <br />
        {props.currentApplication?.internship.internshipLocationAddress1}
        <br />
        {/* {props.currentApplication?.internship.internshipLocationAddress2 ? props.currentApplication?.internship.internshipLocationAddress2 : ''}<br /> */}
        {props.currentApplication?.internship.internshipLocationCity}, {props.currentApplication?.internship.internshipLocationState}{" "}
        {props.currentApplication?.internship.internshipLocationZip}
        <br />
        <br />
        <hr />
        {currentType !== "2" ? (
          <div>
            <label className="preview-label">Student:</label>
            <br />
            {props.currentApplication?.student.firstName} {props.currentApplication?.student.lastName}
            <br />
            <br />
            <label className="preview-label">Is Student an Avenue Scholar:</label>
            <br />
            {props.currentApplication?.student.isAvenueScholar ? "Yes" : "No"}
            <br />
            <br />
            <label className="preview-label">Email:</label>
            <br />
            {props.currentApplication?.student.email}
            <br />
            <br />
            <label className="preview-label">Phone:</label>
            <br />
            {props.currentApplication?.student.phone}
            <br />
            <br />
            <label className="preview-label">High School:</label>
            <br />
            {props.currentApplication?.student.highSchool}
            <br />
            <br />
            <label className="preview-label">Resume:</label>
            <br />
            <a
              // target="_blank"
              // href={`./api/applications/${props.currentApplication?.id}/resume`}
              style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
              onClick={() => downloadResume(props.currentApplication?.id ?? 0, props.currentApplication?.resumeFilename ?? "")}
            >
              Download
            </a>
            <br />
            <hr />
          </div>
        ) : (
          <div></div>
        )}
        {/* <label className="preview-label">How did you hear about this opportunity?:</label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.howDidYouHear ? props.currentApplication?.howDidYouHear : "",
          }}
        />
        <br />
        <br /> */}
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.howDidYouHearOther ? props.currentApplication?.howDidYouHearOther : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">What interests you about this position?:</label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.whatInterestsYou ? props.currentApplication?.whatInterestsYou : "",
          }}
        />
        <br />
        <br />
        <label className="preview-label">
          Why would you be successful in this position?:
        </label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.whyStrongestCandidate ? props.currentApplication?.whyStrongestCandidate : "",
          }}
        />
        <br />
        <br />
        {/* <label className="preview-label">What are your immediate career goals?:</label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.immediateCareerGoals ? props.currentApplication?.immediateCareerGoals : "",
          }}
        />
        <br />
        <br /> */}
        <label className="preview-label">What do you want your life to look like in 5 years?:</label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.longTermCareerGoals ? props.currentApplication?.longTermCareerGoals : "",
          }}
        />
        <br />
        <br />
        
        <label className="preview-label">The expectations for Avenue Scholars Interns are:</label>
        <ul>
          <li>Comply with all part-time employee policies and procedures at your place of
            employment.</li>
          <li>Make any necessary arrangements for time off directly with your supervisor.</li>
          <li>Provide prompt responses to your Avenue Scholars Career Coach.</li>
          <li>Notify your Avenue Scholars Career Coach if your internship ends for any reason.</li>
          <li>Understand that being fired from your internship will result in an F grade for your
            internship course.</li>
          <li>Understand that your internship may be impacted if disciplinary action is taken at
            school or if grades fall.</li>
        </ul>

        <label>
          Do you agree to meet these expectations if you are offered an internship?
        </label>
        <br />
        {/* <InputSwitch
            disabled
            checked={props.currentApplication?.canSatisfyRequirements}
            onChange={(e) => setState({ canSatisfyRequirements: e.value })}
          /> */}
        Yes:{" "}
        <RadioButton
          disabled
          name="canSatisfyRequirements"
          onChange={(e) => setCurrentApplication({ ...(currentApplication as IApplication), canSatisfyRequirements: true })}
          checked={currentApplication?.canSatisfyRequirements === true}
        />
        &nbsp; No:
        <RadioButton
          disabled
          name="canSatisfyRequirements"
          onChange={(e) => setCurrentApplication({ ...(currentApplication as IApplication), canSatisfyRequirements: false })}
          checked={currentApplication?.canSatisfyRequirements === false}
        />
        <br />
        <br />
        <label className="preview-label">If you answered "no" to the previous question, please explain below.:</label>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: props.currentApplication?.canSatisfyRequirementsExplaination ? props.currentApplication?.canSatisfyRequirementsExplaination : "",
          }}
        />
        <br />
        <br />
        {currentType === "2" && currentApplication?.applicationStatus === 10 && (
          <div>
            <a
              target="_blank"
              href={`./api/applications/${props.currentApplication?.id}/resume?currentUserId=${
                (jwt_decode(localStorage.getItem("token") as string) as any).UserId
              }`}
            >
              <img width="40px" src="/images/document.png"></img> Review Attached Resume
            </a>
            <br />
            <br />
            <Button
              label="Replace Resume"
              className=""
              onClick={() => {
                props.history.push(`/applications/${props.currentApplication?.id}/attachresume`);
              }}
            />
            {/* onClick=
              {(e) => {
                fetch(`api/applications/${props.currentApplication?.id}/resume`, {
                  method: "get",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
                  .then((response) => {
                    var data = response.text as unknown as Promise<string>;
                    return data;
                  })
                  .then((data) => {
                    fileDownload(data, "resume.pdf");
                  });
              }} */}
            <br />
            <br />
          </div>
        )}
        {currentType !== "2" && (
          <div>
            <label className="preview-label">Application Status:</label>
            <br />
            <Dropdown
                          value={currentApplication?.applicationStatus}
                          options={statuses}
                          onChange={(e) => setCurrentApplication({ ...(currentApplication as IApplication), applicationStatus: e.value })}
                          placeholder="Select a Status"
                          disabled={currentType == 3} // Set disabled if user is district

            />
            <br />
            <br />
            {currentApplication?.applicationStatus == 3 ? (
              <div>
                <label className="preview-label">Declined Reason:</label>
                <br />
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={currentApplication?.declineReason}
                  onChange={(e) => setCurrentApplication({ ...(currentApplication as IApplication), declineReason: e.target.value })}
                  autoResize={true}
                />

                <br />
              </div>
            ) : (
              <div></div>
            )}
            <Button
              label="Update Status"
              onClick={() => {
                if (currentApplication?.applicationStatus === ApplicationStatus.Declined) {
                  if (currentApplication?.declineReason) {
                    props.updateApplication(currentApplication);
                    notify();
                  } else {
                    alert("You must enter a reason for declining this application to continue.");
                  }
                } else {
                  props.updateApplication(currentApplication as IApplication);
                  notify();
                }
              }}
            />
          </div>
        )}
        {currentType === "2" && (
          <div>
            <label className="preview-label">Application Status:</label>
            <br />
            <Dropdown
              disabled
              value={props.currentApplication?.applicationStatus}
              options={statuses}
              onChange={(e) => setCurrentApplication({ ...(currentApplication as IApplication), applicationStatus: e.value })}
              placeholder="Select a Status"
            />

            <br />
            <br />
            <br />
            {currentApplication?.applicationStatus == 3 && (
              <div>
                <label className="preview-label">Declined Reason:</label>
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.currentApplication?.declineReason ? props.currentApplication?.declineReason : "",
                  }}
                />
                <br />
                <br />
              </div>
            )}

            {currentApplication?.applicationStatus == 10 && (
              <div>
                <Button
                  label="Edit Application"
                  className=""
                  onClick={() => {
                    props.history.push(`/internships/${props.currentApplication?.internshipId}/apply/${props.currentApplication?.id}`);
                  }}
                />
                <Button
                  label="Submit Application"
                  className=""
                  onClick={() => {
                    props.useProfileResume(props.currentApplication?.id ?? 0);
                    setTimeout(() => {
                      props.history.push(`/students/${props.currentApplication?.studentId}/applications`);
                    }, 1500);
                  }}
                />
                <Button
                  label="Archive Application"
                  className="p-button-danger"
                  onClick={() => {
                    props.deleteApplication(currentApplication?.id);
                    props.getApplications();
                    notify();
                    setTimeout(() => {
                      props.history.push(`/students/${props.currentApplication?.studentId}/applications`);
                    }, 1500);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {currentType == "0" && (
          <div>
            <br />
            <Button
              label="Archive Application"
              className="p-button-danger"
              onClick={() => {
                props.deleteApplication(currentApplication?.id ?? 0);
                props.getApplications();
                notify();
                setTimeout(() => {
                  props.history.push(`/applications`);
                }, 1500);
              }}
            />
          </div>
        )}
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.application, ApplicationsStore.actionCreators)(ApplicationView as any);
