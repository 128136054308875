import { AccountType } from "../models/enums/accountType";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import jwt_decode from "jwt-decode";
import { ICohort } from "../models/ICohort";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CohortState {
  selectedCohort?: ICohort;
  cohortList: Array<ICohort>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface CreateCohortAction {
  type: "CREATE_COHORT_BEGIN";
  createCohort: ICohort;
}
export interface CreateCohortSuccessAction {
  type: "CREATE_COHORT_SUCCESS";
}
export interface CreateCohortErrorAction {
  type: "CREATE_COHORT_ERROR";
}
export interface GetCohortsAction {
  type: "GET_COHORTS_BEGIN";
}
export interface GetCohortsSuccessAction {
  type: "GET_COHORTS_SUCCESS";
  data: Array<ICohort>;
}
export interface GetCohortsErrorAction {
  type: "GET_COHORTS_ERROR";
}

export interface UpdateCohortAction {
  type: "UPDATE_COHORT_BEGIN";
  updateCohort: ICohort;
}
export interface UpdateCohortSuccessAction {
  type: "UPDATE_COHORT_SUCCESS";
}
export interface UpdateCohortErrorAction {
  type: "UPDATE_COHORT_ERROR";
}

export interface GetCohortAction {
  type: "GET_COHORT_BEGIN";
  cohortId: number;
}
export interface GetCohortSuccessAction {
  type: "GET_COHORT_SUCCESS";
  data: ICohort;
}
export interface GetCohortErrorAction {
  type: "GET_COHORT_ERROR";
}

export interface DeleteCohortAction {
  type: "DELETE_COHORT_BEGIN";
  cohortId: number;
}
export interface DeleteCohortSuccessAction {
  type: "DELETE_COHORT_SUCCESS";
  data: boolean;
}
export interface DeleteCohortErrorAction {
  type: "DELETE_COHORT_ERROR";
}

export interface ClearSelectedCohortAction {
  type: "CLEAR_SELECTED_COHORT";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | GetCohortsAction
  | GetCohortsSuccessAction
  | GetCohortsErrorAction
  | CreateCohortAction
  | CreateCohortSuccessAction
  | CreateCohortErrorAction
  | UpdateCohortAction
  | UpdateCohortSuccessAction
  | UpdateCohortErrorAction
  | GetCohortAction
  | GetCohortSuccessAction
  | GetCohortErrorAction
  | DeleteCohortAction
  | DeleteCohortSuccessAction
  | DeleteCohortErrorAction
  | RouterAction
  | ClearSelectedCohortAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  createCohortSuccess: () => ({ type: "CREATE_COHORT_SUCCESS" } as CreateCohortSuccessAction),
  createCohortError: () => ({ type: "CREATE_COHORT_ERROR" } as CreateCohortErrorAction),
  clearSelectedCohort: () => ({ type: "CLEAR_SELECTED_COHORT" } as ClearSelectedCohortAction),
  createCohort:
    (createCohort: ICohort): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.cohort) {
        fetch(`api/cohorts`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(createCohort),
        })
          .then((response) => {
            if (response.status === 500) {
              dispatch({ type: "CREATE_COHORT_ERROR" });
            } else {
              var data = response.json() as Promise<ICohort>;
              return data;
            }
          })
          .then((data) => {
            if (data) {
              dispatch({ type: "CREATE_COHORT_SUCCESS" });
                dispatch(push("/confirmation"));
            }
          });

        dispatch({ type: "CREATE_COHORT_BEGIN", createCohort });
      }
    },
  getCohorts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.employer) {
      fetch(`api/cohorts`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createCohort)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICohort>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COHORTS_SUCCESS", data });
        });

      dispatch({ type: "GET_COHORTS_BEGIN" });
    }
  },
  getCohortsSuccess: (data: any) => ({ type: "GET_COHORTS_SUCCESS", data: data } as GetCohortsSuccessAction),
  updateCohortSuccess: () => ({ type: "UPDATE_COHORT_SUCCESS" } as UpdateCohortSuccessAction),
  updateCohortError: () => ({ type: "UPDATE_COHORT_ERROR" } as UpdateCohortErrorAction),
  updateCohort:
    (updateCohort: ICohort): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/cohorts`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateCohort),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_COHORT_SUCCESS" });
            dispatch(push(`/cohorts`));
          });

        dispatch({ type: "UPDATE_COHORT_BEGIN", updateCohort });
      }
    },
  getCohort:
    (cohortId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/cohorts/${cohortId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createCohort)
        })
          .then((response) => {
            var data = response.json() as Promise<ICohort>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COHORT_SUCCESS", data });
          });

        dispatch({ type: "GET_COHORT_BEGIN", cohortId });
      }
    },
  getCohortSuccess: (data: any) => ({ type: "GET_COHORT_SUCCESS", data: data } as GetCohortSuccessAction),
  deleteCohort:
    (cohortId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.employer) {
        fetch(`api/cohorts/${cohortId}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createCohort)
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "DELETE_COHORT_SUCCESS", data });
          });

        dispatch({ type: "DELETE_COHORT_BEGIN", cohortId });
      }
    },
  deleteCohortSuccess: (data: any) => ({ type: "DELETE_COHORT_SUCCESS", data: data } as DeleteCohortSuccessAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CohortState> = (state: CohortState | undefined, incomingAction: Action): CohortState => {
  if (state === undefined) {
    return {
      selectedCohort: undefined,
      cohortList: [],
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "CREATE_COHORT_BEGIN":
      return {
        ...state,
      };
    case "CREATE_COHORT_ERROR":
      return {
        ...state,
        //createCohortErrorMessage: "Cohortname/Email already exists.  Please login to that account, or choose a new cohortname/email address.",
      };
    case "GET_COHORTS_SUCCESS":
      return {
        ...state,
        cohortList: (action as GetCohortsSuccessAction).data,
      };
    case "GET_COHORT_SUCCESS":
      var localState = {
        ...state,
        selectedCohort: (action as GetCohortSuccessAction).data,
      };
      return localState;
    case "DELETE_COHORT_SUCCESS":
      return {
        ...state,
        selectedCohort: undefined,
      };
    case "CLEAR_SELECTED_COHORT":
      return {
        ...state,
        selectedCohort: undefined,
      };
    default:
      return state;
  }
};
