export const APP_ROUTES = {
  DASHBOARD: "/",
  COUNTER: "/counter",
  USER_LIST: "/users",
  USER_EDIT: "/users/:userId",
  COHORT_LIST: "/cohorts",
  COHORT_EDIT: "/cohorts/:cohortId",
  ADMIN_SETTINGS: "/adminsettings",
  LANDING: "/landing",
  ADMIN_STUDENT_EMAIL_BLAST: "/adminsettings/studentemailblast",
  ADMIN_EMPLOYER_EMAIL_BLAST: "/adminsettings/employeremailblast",
  EMPLOYER_LIST: "/employers",
  EMPLOYER_EDIT: "/employers/:employerId",
  STUDENT_EDIT: "/students/:studentId",
  INTERNSHIP_LIST: "/internships",
  STUDENT_INTERNSHIP_LIST: "/internships/student",
  INTERNSHIP_EDIT: "/internships/:internshipId",
  INTERNSHIP_VIEW: "/internships/:internshipId/view",
  INTERNSHIP_APPLY: "/internships/:internshipId/apply",
  INTERNSHIP_APPLY_EDIT: "/internships/:internshipId/apply/:applicationId",
  EMPLOYER_APPLICATION_LIST: "/employers/:employerId/applications",
  STUDENT_APPLICATION_LIST: "/students/:studentId/applications",
  APPLICATION_LIST: "/applications",
  APPLICATION_VIEW: "/applications/:applicationId",
  APPLICATION_ATTACH_RESUME: "/applications/:applicationId/attachresume",
  REPORT_LIST: "/reports",
  REPORT_STUDENT_SCHOOL_COUNTS: "/reports/studentSchoolCounts",
  REPORT_STUDENTS_IN_ROLES: "/reports/studentsInRoles",
  REPORT_STUDENT_INFORMATION: "/reports/studentInformation",
  REPORT_APPLICANTS_PER_ROLE: "/reports/applicantsPerRole",
  REPORT_YOY_PARTNER_PERFORMANCE: "/reports/yoyPartnerPerformance",
  REPORT_STUDENT_APPLICATIONS: "/reports/studentApplications",
};
