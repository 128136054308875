import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IInternship } from "../../../models/IInternship";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { NavLink } from "reactstrap";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";

type InternshipEditProps =
  InternshipStore.InternshipState &
  UserStore.UserState &
  typeof InternshipStore.actionCreators &
  typeof UserStore.actionCreators &
  RouteComponentProps<{}>;

function InternshipEdit(props: InternshipEditProps) {
  const [currentInternship, setCurrentInternship] = useState({
    employerId: 0,
    employer: undefined,
    id: 0,
    created: new Date("1/1/2020"),
    updated: new Date("1/1/2020"),
    internshipTitle: "",
    internshipDomain: "",
    jobDescription: "",
    eligibilityRequirements: "",
    preferredSkills: "",
    workSchedule: "",
    dressCodeRequirements: "",
    requiredTools: "",
    payPerHour: 0,
    payPerHourMax: 0,
    numberOfPositionsAvailable: 1,
    internshipLocationAddress1: "",
    internshipLocationAddress2: "",
    internshipLocationCity: "",
    internshipLocationState: "",
    internshipLocationZip: "",
    isApproved: false,
    isPublished: false,
    alreadyApplied: false,
    profileComplete: false,
    externalJobApplicationLink: "",
    youTubeVideoLink: "",
    selectedCohortIds: [],
    whoWeAre: "",
    whatYouCanExpect: "",
    summerOnly: false,
    internshipLength: ""

  } as IInternship);
  const [employerList, setEmployerList] = useState([{ label: "", value: "" }]);
  const [cohortList, setCohortList] = useState([{ label: "", value: "" }]);
  const [currentInternshipId, setCurrentInternshipId] = useState(0);
  const [selectedCohorts, setSelectedCohorts] = useState(['1']);

  useEffect(() => {
    const currentInternshipId = (props.match.params as any).internshipId;
    console.log(currentInternshipId);
    if (currentInternshipId > 0) {
      props.getInternship(currentInternshipId);
    } else {
      //props.clearCurrentInternship();
      setCurrentInternship({
        employerId: 0,
        employer: undefined,
        id: 0,
        created: new Date("1/1/2020"),
        updated: new Date("1/1/2020"),
        internshipTitle: "",
        internshipDomain: "",
        jobDescription: "",
        eligibilityRequirements: "",
        preferredSkills: "",
        workSchedule: "",
        dressCodeRequirements: "",
        requiredTools: "",
        payPerHour: 0,
        payPerHourMax: 0,
        numberOfPositionsAvailable: 1,
        internshipLocationAddress1: "",
        internshipLocationAddress2: "",
        internshipLocationCity: "",
        internshipLocationState: "",
        internshipLocationZip: "",
        isApproved: false,
        isPublished: false,
        alreadyApplied: false,
        profileComplete: false,
        externalJobApplicationLink: "",
        youTubeVideoLink: "",
        selectedCohortIds: [],
        whoWeAre: "",
        whatYouCanExpect: "",
        summerOnly: false,
        internshipLength: ""
      });
    }
    setCurrentInternshipId(currentInternshipId);
    props.getEmployers();
    props.getCohorts();
  }, []);

  useEffect(() => {
    if (props.currentInternship != undefined) {
      setCurrentInternship(props.currentInternship);
      setSelectedCohorts(props.currentInternship?.selectedCohortIds ?? []);
    }
  }, [props.currentInternship]);


  useEffect(() => {
    console.log(selectedCohorts);
    setCurrentInternship({ ...currentInternship as IInternship, selectedCohortIds: selectedCohorts })
    console.log(currentInternship?.selectedCohortIds);
  }, [selectedCohorts]);

  useEffect(() => {
    const list = props.employerList.map((employer) => {
      return { label: employer.employerName, value: employer.id.toString() };
    });
    setEmployerList(list);
  }, [props.employerList]);

  useEffect(() => {
    const list = props.cohortList.map((cohort) => {
      return { label: `${cohort.name}(${new Date(cohort.start ?? new Date()).toLocaleDateString()}-${new Date(cohort.end ?? new Date()).toLocaleDateString()})`, value: cohort.id.toString() };
    });
    setCohortList(list);
    // if (selectedCohorts.length === 1 && selectedCohorts[0] === '0') {
    //   setSelectedCohorts([props.cohortList[0].id as unknown as string]);
    // }
  }, [props.cohortList]);

  const domains = [
    { label: "Automotive & Diesel", value: "Automotive & Diesel" },
    { label: "Business", value: "Business" },
    // { label: "Engineering", value: "Engineering" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Information Technology", value: "Information Technology" },
    // { label: "Nonprofit Management", value: "Nonprofit Management" },
    { label: "Trades/Labor/Manufacturing", value: "Trades/Labor/Manufacturing" },
    ];

    const internshipLengths = [
        { label: "Year Long", value: "Year Long" },
        { label: "Summer Only", value: "Summer Only" },    
        { label: "Summer Only with option to extend", value: "Summer Only with option to extend" }    
    ];



  const currentType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType;


  return (
    <React.Fragment>
      <Card title="Edit Internship" subTitle="">
        {currentType === "0" ? (
          <div>
            <label>Employer:</label>
            <br />
            <Dropdown
              value={currentInternship?.employerId?.toString()}
              options={employerList}
              onChange={(e) => {
                setCurrentInternship({ ...currentInternship, employerId: e.value } as IInternship);
              }}
              placeholder="Select an Employer"
            />
            <br />
            <br />
          </div>
        ) : (
          <div></div>
        )}

        <label>Internship Title:</label>
        <br />
        <InputText
          name="internshipTitle"
          value={currentInternship?.internshipTitle ? currentInternship?.internshipTitle : ""}
          onChange={(e) => setCurrentInternship({ ...currentInternship, internshipTitle: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)}
        />
        <br />
        <br />

        <label>Domain:</label>
        <br />
        <Dropdown
          value={currentInternship?.internshipDomain}
          options={domains}
          onChange={(e) => {
            setCurrentInternship({ ...currentInternship, internshipDomain: e.value } as IInternship);
          }}
          placeholder="Select a Domain"
        />
        <br />
        <br />

              <label>Length of Internship:</label>
              <br />
              <Dropdown
                  value={currentInternship?.internshipLength}
                  options={internshipLengths}
                  onChange={(e) => {
                      setCurrentInternship({ ...currentInternship, internshipLength: e.value } as IInternship);
                  }}
                  placeholder="Select an Internship Length"
              />
              {/*<InputSwitch*/}
              {/*    checked={currentInternship?.summerOnly || false}*/}
              {/*    onChange={(e) => setCurrentInternship({ ...currentInternship, summerOnly: e.value } as IInternship)}*/}
              {/*/>*/}
              <br />
              <br />

        <label>Who We Are:</label>
        <div className="guideText">Most of our students are unaware of what your company does. Tell them about what you do, what your mission is, what the work environment is like, and describe the culture of your company. You can also upload a YouTube video about your company if you’d like.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.whoWeAre}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, whoWeAre: e.htmlValue as string } as IInternship)}
        />
        <br />


        <label>A Day in the Life of Our Interns:</label>
        <div className="guideText">Please describe what a typical day for an intern in this position might look like. To give interns a true feel of what working at your company is like, we recommend interviewing a current intern or creating a “day in the life” video to be uploaded into this section.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.jobDescription}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, jobDescription: e.htmlValue as string } as IInternship)}
        />
        <br />

        <label>A Typical Schedule Looks Like:</label>
        <div className="guideText">Please describe what the average hours per week an intern is expected to work in the summer and during the school year. Please list what hours your business is open. If you are flexible with the amount of hours an intern works, please note that in this section.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.workSchedule}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, workSchedule: e.htmlValue as string } as IInternship)}
        />
        <br />

        <label>You’ll Be Successful Here When You:</label>
        <div className="guideText">Use this space to describe what sort of attributes and skills students need to be successful at this internship.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.preferredSkills}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, preferredSkills: e.htmlValue as string } as IInternship)}
        />
        <br />


        <label>You Should Wear:</label>
        <div className="guideText">Please describe your company’s dress code in this section. Also include if students need to provide their own materials such as steel-toed boots, tools, safety glasses, etc.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.dressCodeRequirements}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, dressCodeRequirements: e.htmlValue as string } as IInternship)}
        />
        <br />


        <label>To Work Here, You Need:</label>
        <div className="guideText">Please indicate any requirements you have for your interns here. These could include age restrictions, background check, drug screening, vaccination requirements, prior class requirements, etc.
        </div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.eligibilityRequirements}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, eligibilityRequirements: e.htmlValue as string } as IInternship)}
        />
        <br />

        <label>What You Can Expect From Us:</label>
        <div className="guideText">This is a great space to discuss what mentorship and support look like at your company. Describe to students what you will teach them during their internship. This is also a space for you to include your company’s diversity and equal opportunity statement.</div>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.whatYouCanExpect}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, whatYouCanExpect: e.htmlValue as string } as IInternship)}
        />
        <br />



        {/* <label>Required Tools:</label>
        <br />
        <Editor
          style={{ height: "320px" }}
          value={currentInternship?.requiredTools}
          onTextChange={(e) => setCurrentInternship({ ...currentInternship, requiredTools: e.htmlValue as string } as IInternship)}
        />
        <br /> */}

        <label>Pay Per Hour Minimum:</label>
        <br />
        {/* <InputNumber value={currentInternship?.payPerHour} onChange={(e) => setCurrentInternship({ ...currentInternship,  payPerHour: e.value } as IInternship)} min={9} max={25} step={.01} mode="decimal"  minFractionDigits={2} /><br /><br /> */}
        <InputNumber
          value={currentInternship?.payPerHour ? currentInternship?.payPerHour : 0}
          onChange={(e) => setCurrentInternship({ ...currentInternship, payPerHour: e.value } as IInternship)}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
        <br />
        <br />
        <label>Pay Per Hour Maximum:</label>
        <br />
        {/* <InputNumber value={currentInternship?.payPerHour} onChange={(e) => setCurrentInternship({ ...currentInternship,  payPerHour: e.value } as IInternship)} min={9} max={25} step={.01} mode="decimal"  minFractionDigits={2} /><br /><br /> */}
        <InputNumber
          value={currentInternship?.payPerHourMax ? currentInternship?.payPerHourMax : 0}
          onChange={(e) => setCurrentInternship({ ...currentInternship, payPerHourMax: e.value } as IInternship)}
          mode="currency"
          currency="USD"
          locale="en-US"
        />
        <br />
        <br />

        <label>Number Of Positions Available:</label>
        <br />
        {/* <InputNumber value={currentInternship?.numberOfPositionsAvailable} onChange={(e) => setCurrentInternship({ ...currentInternship,  numberOfPositionsAvailable: e.value } as IInternship)} min={0} max={100} /><br /><br /> */}
        <InputNumber
          value={currentInternship?.numberOfPositionsAvailable ? currentInternship?.numberOfPositionsAvailable : 0}
          onChange={(e) => setCurrentInternship({ ...currentInternship, numberOfPositionsAvailable: e.value } as IInternship)}
        />
        <br />
        <br />
        <label>Internship Location:</label>
        <br />

        <label>Address 1:</label>
        <br />
        <InputText
          name="internshipLocationAddress1"
          value={currentInternship?.internshipLocationAddress1 ? currentInternship.internshipLocationAddress1 : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, internshipLocationAddress1: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>Address2:</label>
        <br />
        <InputText
          name="internshipLocationAddress2"
          value={currentInternship?.internshipLocationAddress2 ? currentInternship?.internshipLocationAddress2 : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, internshipLocationAddress2: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>City:</label>
        <br />
        <InputText
          name="internshipLocationCity"
          value={currentInternship?.internshipLocationCity ? currentInternship?.internshipLocationCity : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, internshipLocationCity: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>State:</label>
        <br />
        <InputText
          name="internshipLocationState"
          value={currentInternship?.internshipLocationState ? currentInternship?.internshipLocationState : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, internshipLocationState: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>Zip:</label>
        <br />
        <InputText
          name="internshipLocationZip"
          value={currentInternship?.internshipLocationZip ? currentInternship?.internshipLocationZip : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, internshipLocationZip: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>External Application Link:</label>
        <br />
        <InputText
          name="externalJobApplicationLink"
          value={currentInternship?.externalJobApplicationLink ? currentInternship?.externalJobApplicationLink : ""}
          onChange={(e) =>
            setCurrentInternship({ ...currentInternship, externalJobApplicationLink: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)
          }
        />
        <br />
        <br />

        <label>Youtube Video Link:</label>
        <div className="guideText">To upload a video into the Avenue Scholars Interns portal, you must first upload your video to YouTube.  When your video is uploaded to YouTube, the visibility option will allow you to choose who can see your video. We recommend making the video “Unlisted,” meaning only those with a link can view, however this is entirely up to you. Once the video is uploaded into YouTube, copy the URL link and paste into the field below.</div>
        <br />
        <InputText
          name="youTubeVideoLink"
          value={currentInternship?.youTubeVideoLink ? currentInternship?.youTubeVideoLink : ""}
          onChange={(e) => setCurrentInternship({ ...currentInternship, youTubeVideoLink: (e.target as unknown as HTMLTextAreaElement).value } as IInternship)}
        />
        <br />
        <br />

        <label>Assigned Cohort(s):</label>
        <br />
        <MultiSelect optionLabel="label" optionValue="value" value={selectedCohorts} options={cohortList} showSelectAll={false} onChange={(e) => setSelectedCohorts(e.value)} />
        <br />
        <br />



        <label>Published:</label>
        <br />
        <InputSwitch
          checked={currentInternship?.isPublished}
          onChange={(e) => setCurrentInternship({ ...currentInternship, isPublished: e.value } as IInternship)}
        />
        <br />
        <br />

        {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
          <div>
            <label>Approved:</label>
            <br />
            <InputSwitch
              checked={currentInternship?.isApproved}
              onChange={(e) => setCurrentInternship({ ...currentInternship, isApproved: e.value } as IInternship)}
            />
            <br />
            <br />
          </div>
        ) : (
          ""
        )}

        <Button
          label="Save"
          onClick={() => {
            if (currentInternshipId == 0) {
              props.createInternship(currentInternship as IInternship);
            } else {
                if (currentInternship) {
                    currentInternship.selectedCohortIds = selectedCohorts;
                props.updateInternship(currentInternship);
              }
            }
          }}
        />

        {currentInternship?.id ?? 0 > 0 ? (
          <span>
            <Button
              label="Preview"
              onClick={() => {
                props.history.push(`/internships/${currentInternship?.id}/view`);
              }}
            />
            <Button
              label="Archive"
              className="p-button-danger"
              onClick={() => {
                props.deleteInternship(currentInternshipId);
                props.getInternships();
                props.history.push(`/internships`);
              }}
            />
          </span>
        ) : (
          ""
        )}
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.internship, InternshipStore.actionCreators)(InternshipEdit as any);
