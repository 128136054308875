import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

type YoYPartnerPerformanceProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;

function YoYPartnerPerformance(props: YoYPartnerPerformanceProps) {
  let dt: DataTable | null | undefined;
  const [yoyPartnerPerformance, setYoYPartnerPerformance] = React.useState(props.yoyPartnerPerformance);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);


  useEffect(() => {
    props.getYoYPartnerPerformance();
    props.getCohorts();
  }, []);

  useEffect(() => {
    setYoYPartnerPerformance(props.yoyPartnerPerformance);
  }, [props.applicantsPerRole]);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }


  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        value={globalFilter ?? ''}
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />
    </div>
  );

  return (
    <React.Fragment>
      <h1>Year Over Year Partner Performance</h1>

      <DataTable
        ref={(el) => (dt = el)}
        value={yoyPartnerPerformance}
        //onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
      >
        <Column style={{ width: '120px' }} field="employerName" header="Employer Name" sortable={true} />
        <Column style={{ width: '60px' }} field="employerCreated" header="Grad Year" sortable={true} />
        <Column style={{ width: '80px' }} field="cohort" header="Cohort" sortable={true} />
        <Column style={{ width: '120px' }} field="internshipDomain" header="Domain" sortable={true} />
        <Column style={{ width: '120px' }} field="internshipTitle" header="Title" sortable={true} />
        <Column style={{ width: '40px' }} field="numberOfPositions" header="# of Jobs" sortable={true} />
        <Column style={{ width: '40px' }} field="numberOfApplications" header="# of Apps" sortable={true} />
        <Column style={{ width: '40px' }} field="numberOfHires" header="# of Hires" sortable={true} />
      </DataTable>
    </React.Fragment>
  );
}

function moneyTemplate(rowData: any, column: any) {
  var amount = parseFloat(rowData[column.field]);

  return <div>{amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>;
}



function handleRowClick(e: any) {
  //this.props.history.push(`/reports/${e.data.route}`);
}


export default connect((state: ApplicationState) => state.report, ReportStore.actionCreators)(YoYPartnerPerformance as any);
