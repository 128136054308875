import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as AdminSettingsStore from "../../../store/adminSettings";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IAdminSettings } from "../../../models/IAdminSettings";
import { Card } from "primereact/card";
import { ToastContainer, toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";

type LandingProps = AdminSettingsStore.AdminSettingsState & typeof AdminSettingsStore.actionCreators & RouteComponentProps<{}>;

function Landing(props: LandingProps) {
  const [currentAdminSettings, setCurrentAdminSettings] = React.useState(props.currentAdminSettings);

  useEffect(() => {
    props.getAdminSettings(1);
  }, []);

  useEffect(() => {
    setCurrentAdminSettings(props.currentAdminSettings);
  }, [props.currentAdminSettings]);

  const getContent = () => {
    switch ((jwt_decode(localStorage.getItem("token") as string) as any).AccountType) {
      case "0":
        return <span dangerouslySetInnerHTML={{ __html: currentAdminSettings?.adminHomeScreenContent ?? "" }} />;
      case "1":
        return <span dangerouslySetInnerHTML={{ __html: currentAdminSettings?.employerHomeScreenContent ?? "" }} />;
      case "2":
        return <span dangerouslySetInnerHTML={{ __html: currentAdminSettings?.studentHomeScreenContent ?? "" }} />;
      case "3":
        return <span dangerouslySetInnerHTML={{ __html: currentAdminSettings?.districtHomeScreenContent ?? "" }} />;
      default:
        return "";
    }
  };

  return <React.Fragment>{getContent()}</React.Fragment>;
}

export default connect((state: ApplicationState) => state.adminSettings, AdminSettingsStore.actionCreators)(Landing as any);
