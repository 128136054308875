import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as InternshipStore from '../../../store/internship';
import * as UserStore from '../../../store/user';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

type StudentInternshipListProps =
    InternshipStore.InternshipState & UserStore.UserState &
    typeof InternshipStore.actionCreators & typeof UserStore.actionCreators &
    RouteComponentProps<{}>;

class StudentInternshipList extends React.PureComponent<StudentInternshipListProps, any> {
    dt: DataTable | null | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            domains: [
                { label: 'Automotive & Diesel', value: 'Automotive & Diesel' },
                { label: 'Business', value: 'Business' },
                // { label: "Engineering", value: "Engineering" },
                { label: 'Healthcare', value: 'Healthcare' },
                { label: "Education", value: "Education" },
                { label: 'Information Technology', value: 'Information Technology' },
                // { label: "Nonprofit Management", value: "Nonprofit Management" },
                { label: 'Trades/Labor/Manufacturing', value: 'Trades/Labor/Manufacturing' }
            ]
        }

        this.onDomainChange = this.onDomainChange.bind(this);
        this.onExport = this.onExport.bind(this);
    }

    componentDidMount() {
        this.props.getInternships();
    }

    approvedTemplate(rowData: any) {
        let value = rowData.isApproved as boolean;

        if (value) {
            return <span>Yes</span>;
        } else {
            return <span>No</span>;
        }
    }

    publishedTemplate(rowData: any) {
        let value = rowData.isPublished as boolean;

        if (value) {
            return <span>Yes</span>;
        } else {
            return <span>No</span>;
        }
    }

    onDomainChange(event: any) {
        if (this.dt) {
            this.dt.filter(event.value, 'internshipDomain', 'equals');
            this.setState({ selectedDomain: event.value });
        }

    }

    onExport() {
        if (this.dt) this.dt.exportCSV({ selectionOnly: false });
    }

    public render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText type="search" onInput={(e) => this.setState({ globalFilter: (e.target as HTMLTextAreaElement).value })} placeholder="Global Search" size={50} />
                <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.onExport}></Button>
            </div>
        );

        const domainFilter = <Dropdown style={{ width: '100%' }} placeholder="Select a Domain" value={this.state.selectedDomain} options={this.state.domains} onChange={this.onDomainChange} showClear />;


        return (
            <React.Fragment>
                <h1>Internships</h1>

                <DataTable
                    ref={(el) => this.dt = el}
                    value={this.props.internshipList}
                    onRowClick={$event => this.handleRowClick($event)}
                    selectionMode="single"
                    header={header}
                    globalFilter={this.state.globalFilter}
                >
                    <Column style={{ width: '240px' }} field="employer.employerName" header="Employer" sortable={true} />
                    <Column field="internshipTitle" header="Title" sortable={true} />
                    <Column style={{ width: '280px' }} field="internshipDomain" header="Domain" sortable={true} filter={true} filterElement={domainFilter} />
                    <Column style={{ width: '120px' }} field="payPerHour" header="Pay/Hr" body={this.payTemplate} sortable={true} />
                    <Column field="internshipLocationCity" header="Location" body={this.locationTemplate} sortable={true} />
                    <Column style={{ display: 'none' }} field="internshipLocationState" />
                    <Column style={{ width: '100px' }} field="numberOfPositionsAvailable" header="# Pos" sortable={true} />
                    <Column style={{ width: '150px' }} field="internshipLength" header="Length of Internship" sortable={true} />

                </DataTable>
            </React.Fragment>
        );
    }

    moneyTemplate(rowData: any, column: any) {
        var amount = parseFloat(rowData[column.field]);

        return <div>
            {amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </div>;
    }

    payTemplate(rowData: any, column: any) {
        var minAmount = parseFloat(rowData['payPerHour']);
        var maxAmount = parseFloat(rowData['payPerHourMax']);

        return <div>
            {minAmount ? minAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}-{maxAmount ? maxAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}
        </div>;
    }

    locationTemplate(rowData: any, column: any) {
        var location = rowData[column.field];
        var locationState = rowData['internshipLocationState']

        if (!!!location) {
            location = 'N/A';
        }
        else {
            location = location + ', ' + locationState;
        }

        return <div>
            {location}
        </div>;
    }

    handleRowClick(e: any) {
        this.props.history.push(`/internships/${e.data.id}/view`);
    }
};

export default connect(
    (state: ApplicationState) => state.internship,
    InternshipStore.actionCreators
)(StudentInternshipList as any);
