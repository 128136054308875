import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as InternshipStore from "../../../store/internship";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { IInternship } from "../../../models/IInternship";
import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { Alert, Label } from "reactstrap";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";

type InternshipViewProps = InternshipStore.InternshipState &
  UserStore.UserState &
  typeof InternshipStore.actionCreators &
  typeof UserStore.actionCreators &
  RouteComponentProps<{}>;

function InternshipView(props: InternshipViewProps) {
  const [currentInternship, setCurrentInternship] = useState(props.currentInternship);
  const [currentAdminSettings, setCurrentAdminSettings] = useState(props.currentAdminSettings);
  const [currentInternshipId, setCurrentInternshipId] = useState(0);
  const [domains, setDomains] = useState([
    { label: "Automotive & Diesel", value: "Automotive & Diesel" },
    { label: "Business", value: "Business" },
    // { label: "Engineering", value: "Engineering" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Information Technology", value: "Information Technology" },
    // { label: "Nonprofit Management", value: "Nonprofit Management" },
    { label: "Trades/Labor/Manufacturing", value: "Trades/Labor/Manufacturing" },
  ]);
  const [currentType, setCurrentType] = useState((jwt_decode(localStorage.getItem("token") as string) as any).AccountType);
  const [currentStudentId, setCurrentStudentId] = useState((jwt_decode(localStorage.getItem("token") as string) as any).StudentId);

  useEffect(() => {
    const currentInternshipId = (props.match.params as any).internshipId;
    setCurrentInternshipId(currentInternshipId);

    if (currentInternshipId > 0) {
      props.getInternship(currentInternshipId);
    } else {
      props.clearCurrentInternship();
    }
    props.getAdminSettings(1);
  }, []);

  useEffect(() => {
    setCurrentInternship(props.currentInternship);
  }, [props.currentInternship]);

  useEffect(() => {
    setCurrentAdminSettings(props.currentAdminSettings);
  }, [props.currentAdminSettings]);

  return (
    <React.Fragment>
      {currentType === "2" || currentType === "3" ? (
        <Button
          label="Back To List"
          onClick={() => {
            props.history.push(`/internships/student`);
          }}
        />
      ) : (
        <Button
          label="Back"
          onClick={() => {
            props.history.push(`/internships/${currentInternship?.id}`);
          }}
        />
      )}

      <Card title="" subTitle="" className="preview-card">
              {currentInternship?.employerId && (
                  <img style={{ maxHeight: "300px", maxWidth: "500px" }} src={`./api/employers/${currentInternship?.employerId}/logo`}></img>
              )}
        <br />
        <br />
        <h1>{currentInternship?.employer ? currentInternship?.employer.employerName : ""}</h1>
        <br />
        {currentInternship?.youTubeVideoLink && (
          <div>
            <iframe
              width="560"
              height="315"
              src={`${currentInternship.youTubeVideoLink}`}
              //src={`https://www.youtube.com/embed/${currentApplication.internship.youTubeVideoLink}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <br />
          </div>
        )}
        <label className="preview-label">Internship Title:</label>&nbsp;
        {currentInternship?.internshipTitle}
        <br />
        <br />
        <label className="preview-label">Internship Domain:</label>&nbsp;
        {currentInternship?.internshipDomain}
        <br />
        <br />
        <label className="preview-label">Who We Are:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.whoWeAre ? currentInternship?.whoWeAre : "" }} />
        <br />
        <br />
        <label className="preview-label">A Day in the Life of Our Interns:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.jobDescription ? currentInternship?.jobDescription : "" }} />
        <br />
        <br />
        <label className="preview-label">A Typical Schedule Looks Like:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.workSchedule ? currentInternship?.workSchedule : "" }} />
        <br />
        <br />
        <label className="preview-label">You'll Be Successful Here When You:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.preferredSkills ? currentInternship?.preferredSkills : "" }} />
        <br />
        <br />
        <label className="preview-label">You Should Wear:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.dressCodeRequirements ? currentInternship?.dressCodeRequirements : "" }} />
        <br />
        <br />
        <label className="preview-label">To Work Here, You Need:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.eligibilityRequirements ? currentInternship?.eligibilityRequirements : "" }} />
        <br />
        <br />
        <label className="preview-label">What You Can Expect From Us:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.whatYouCanExpect ? currentInternship?.whatYouCanExpect : "" }} />
        <br />
        <br />


        {/* <label className="preview-label">Required Tools:</label>&nbsp;
        <span dangerouslySetInnerHTML={{ __html: currentInternship?.requiredTools ? currentInternship?.requiredTools : "" }} />
        <br />
        <br /> */}
        <label className="preview-label">Pay Per Hour Minimum:</label>&nbsp; ${currentInternship?.payPerHour}
        <br />
        <br />
        <label className="preview-label">Pay Per Hour Maximum:</label>&nbsp; ${currentInternship?.payPerHourMax}
        <br />
        <br />
        <label className="preview-label">Number Of Positions Available:</label>&nbsp;
        {currentInternship?.numberOfPositionsAvailable}
        <br />
        <br />
        <label className="preview-label">Internship Location:</label>
        <br />
        {currentInternship?.internshipLocationAddress1}
        <br />
        {/* {currentInternship?.internshipLocationAddress2 ? currentInternship?.internshipLocationAddress2 : ''}<br /> */}
        {currentInternship?.internshipLocationCity}, {currentInternship?.internshipLocationState} {currentInternship?.internshipLocationZip}
        <br />
        <br />
        {currentType === "2" && !!!currentInternship?.alreadyApplied ? (
          <Button
            disabled={!(currentAdminSettings.applicationOpenDate <= new Date() && currentAdminSettings.applicationCloseDate >= new Date())}
            label="Apply to this internship"
            onClick={() => {
              if (currentInternship?.profileComplete) {
                props.history.push(`/internships/${currentInternship?.id}/apply`);
              } else {
                alert("You must complete your student profile before applying.");
                props.history.push(`/students/${currentStudentId}`);
              }
            }}
          />
        ) : undefined}{" "}
        {currentType === "2" && !!currentInternship?.alreadyApplied ? (
          <div>
            <Button
              disabled
              label="Apply to this internship"
              onClick={() => {
                props.history.push(`/internships/${currentInternship?.id}/apply`);
              }}
            />
            <h3>Already Applied</h3>
          </div>
        ) : undefined}
      </Card>
    </React.Fragment>
  );
}

export default connect((currentInternship?: ApplicationState) => currentInternship?.internship, InternshipStore.actionCreators)(InternshipView as any);
